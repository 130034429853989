import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from '../index'

export type notificationProps = {
  message: null,
  visible: boolean,
  notificationType: string,
  update: boolean
}

const initialState: notificationProps = {
  message: null,
  visible: false,
  notificationType: "info",
  update: false,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    show: (state, { payload }) => ({
      ...state,
      message: payload.message,
      visible: true,
      notificationType: payload.notificationType,
      update: payload.update,
    }),
    hideNotification: () => ({ ...initialState }),
  },
});

const { show } = notificationSlice.actions;
export const { hideNotification } = notificationSlice.actions;

export const showNotification = (message:string, notificationType?:string, update?:any) => async (dispatch:AppDispatch) => {
 dispatch(show({ message, notificationType, update }))
};

export default notificationSlice.reducer;
