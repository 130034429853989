import { Box } from '@mui/material'
import { ConfirmButton } from '../Button'
import { useAppSelector } from '../../hooks/redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { path } from '../../enum/constant'
import { t } from 'i18next'

export const CartFooter = () => {

  const total = useAppSelector(state => state.cart.total)
  const length = useAppSelector(state => state.cart.length)
  const location = useLocation()
  const navigate = useNavigate()
  if (location.pathname !== path.cart()) return null

  const handleConfirm = () => navigate(path.payment())

  const renderButton = () => {
    const classStyle = length ? styles.confirmBox : { ...styles.confirmBox, visibility: 'hidden' }
    return (
      <Box sx={classStyle}>
        <ConfirmButton onClick={handleConfirm} children={t("cart.buttons.confirm")}/>
      </Box>
    )
  }

  return (
     <Box>
       <Box sx={styles.totalBox}>
         <Box fontWeight={'500'}>{t("cart.total")}</Box>
         <Box>$ {total.toLocaleString('en-US', { minimumFractionDigits: 2 })}</Box>
       </Box>
       {renderButton()}
     </Box>
  )
}

const styles = {
  totalBox: {
    color: '#ffffff',
    fontSize: '40px',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'space-between',
  },
  confirmBox: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '48px',
  },
}