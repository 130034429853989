import { path } from '../enum/constant'
import { t } from 'i18next'

export const parseParams = (param?: string): string[] | null => {
  if (!param || Array.isArray(param)) return null
  return param.split('_')
}

export const getImage = (url: string) => {
  return process.env.REACT_APP_SERVER_URL + url
}

export const removeHeader = (pathname: string) => {
  switch (pathname) {
    case path.cash():
    case path.payment():
    case path.counter():
    case path.smsPhonePage():
    case path.phonePage():
    case path.codePage():
    case path.coins():
    case path.qrCodeReady():
    case path.scanQr():
    case path.cryptoPatience():
    case path.cryptoInfo():
    case path.cryptoCoins():
      return true
    default:
      return false
  }
}

export const getTextInfoPage = (type: string, price?: number, cash_amount?: number, coins_amount?: number, coin?: string): string[] => {
  let title = ''
  let subTitle = ''
  if (type === 'thank' || type === 'printCounterReceipt') {
    title = t("info.title")
    subTitle = t("info.receipt")
  } else if (type === 'cryptoInfo') {
    title = `${t("info.confirmation")}${price?.toLocaleString('en-US', { minimumFractionDigits: 2 })}.`
    subTitle = t("info.receiveSms")
  } else if (type === 'cryptoWhoops') {
    title = t("info.whoops")
    subTitle = `${t("info.receive")} ${coins_amount} ${coin?.toUpperCase()} ($${cash_amount})`
  } else if (type === 'cryptoThank') {
    title = t("info.title")
    subTitle = t("info.receipt")
  } else if (type === 'printCryptoReceipt') {
    title = t("info.title")
    subTitle = t("info.receipt")
  } else if (type === 'smsCryptoReceipt') {
    title = t("info.title")
    subTitle = t("info.smsReceipt")
  }
  return [title, subTitle]
}

export const formatCurrency = (num: number) => {
  return (num || 0).toLocaleString('en-US', { minimumFractionDigits: 2 })
}