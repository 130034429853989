import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch, GetState } from '../index'
import axios, { AxiosError } from 'axios'
import { handleError } from '../error/errorSlice'
import { NavigateFunction } from 'react-router'
import {successType} from "../../pages/Info/InfoActionButtons";

type errorType = null | object | string;

type itemCartType = {
  id: number
  count: number
  price: number
  name: string
  image: any
  size: string
}

type initialStateType = {
  length: number
  cart: Record<number, itemCartType>
  total: number
  orderId: number
  cartLoading: boolean;
  error: errorType;
  infoActionButtonPressed: boolean;
}

const initialState: initialStateType = {
  length: 0,
  cart: {},
  total: 0,
  orderId: 0,
  cartLoading: false,
  error: null,
  infoActionButtonPressed: false,
}

const cartSlice = createSlice({
  name: 'cartSlice',
  initialState,
  reducers: {
    addItem: (state, { payload }: { payload: itemCartType }) => {
      const newCount = (state.cart[payload.id]?.count || 0) + payload.count
      state.cart = { ...state.cart, [payload.id]: { ...payload, count: newCount } }
    },
    removeItem: (state, { payload }: { payload: number }) => {
      const copy = { ...state.cart }
      delete copy[payload]
      state.cart = copy
    },

    updateItem: (state, { payload }: { payload: itemCartType }) => {
    },
    updateCount: (state, { payload }: { payload: { id: number, count: number } }) => {
      const newCount = payload.count
      const copy = { ...state.cart[payload.id], count: newCount }
      state.cart = { ...state.cart, [payload.id]: copy }
    },
    setTotal: (state, { payload }: { payload: number }) => {
      state.total = payload
    },
    updateLength: (state) => {
      state.length = Object.keys(state.cart).length
    },
    setOrderId: (state, { payload }: { payload: number }) => {
      state.orderId = payload
    },
    setCartLoading: (state, {payload}: {payload: boolean}) => {
      state.cartLoading = payload
    },
    setError: (state, {payload}: {payload: errorType}) => {
      state.error = payload
    },
    setInfoActionButton: (state, {payload}: {payload: boolean}) => {
      state.infoActionButtonPressed = payload
    }
  },
})

const { addItem, removeItem, updateCount, setTotal, updateLength , setCartLoading, setError} = cartSlice.actions
export const { setOrderId, setInfoActionButton} = cartSlice.actions
export default cartSlice.reducer

export const addItemCart = (item: any) => (dispatch: AppDispatch) => {
  dispatch(addItem({ id: item.id, count: item.count, price: item.price, image: item.image, name: item.name, size: item.size }))
  dispatch(updateLength())
  dispatch(updateTotal())
}

export const removeItemCart = (id: number) => (dispatch: AppDispatch) => {
  dispatch(removeItem(id))
  dispatch(updateLength())
  dispatch(updateTotal())
}

export const updateItemCart = () => (dispatch: AppDispatch) => {

}

export const updateCountItem = (type: 'add' | 'rem', id: number) => (dispatch: AppDispatch, getState: GetState) => {
  const { cart } = getState().cart
  if (type === 'add') {
    const newCount = cart[id].count + 1
    if (newCount < 1) return
    dispatch(updateCount({ id: id, count: newCount }))
  }
  if (type === 'rem') {
    const newCount = cart[id].count - 1
    if (newCount < 1) return
    dispatch(updateCount({ id: id, count: newCount }))
  }

  dispatch(updateTotal())
}

export const updateTotal = () => (dispatch: AppDispatch, getState: GetState) => {
  const cart = getState().cart.cart
  let price = 0
  Object.values(cart).forEach((value) => {
    price += value.count * value.price
  })
  dispatch(setTotal(price))
}

export const confirmOrder = (navigate: NavigateFunction) => async (dispatch: AppDispatch, getState: GetState) => {
  try {
    const cart = getState().cart.cart
    const products = Object.values(cart).map((v) => ({ quantity: v.count, product_item_id: v.id }))
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/order/create/`, { products }, { headers })
    return response?.data?.token
  } catch (err: AxiosError | any) {
    dispatch(handleError(err, navigate))
  }
}

export const printReceipt = (navigate: NavigateFunction, onSuccess?: (successType: successType) => void) => async (dispatch: AppDispatch) => {
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }

    dispatch(setCartLoading(true))
    dispatch(setError(null))
    await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/kiosk/receipt/print/`, {}, { headers })
    dispatch(setCartLoading(false))
    typeof onSuccess === 'function' ? onSuccess('receipt') : null
  } catch (err: AxiosError | any) {
    dispatch(setCartLoading(false))
    dispatch(setInfoActionButton(false));
    dispatch(setError(err))
    dispatch(handleError(err, navigate))
  }
}

export const sendSMS = (phone_number: string, navigate: NavigateFunction, onSuccess?: (successType: successType) => void) => async (dispatch: AppDispatch) => {
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }

    dispatch(setCartLoading(true))
    dispatch(setError(null))
    await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/kiosk/receipt/send-sms/`, {phone_number}, { headers })
    dispatch(setCartLoading(false))
    typeof onSuccess === 'function' ? onSuccess('sms') : null;
  } catch (err: AxiosError | any) {
    dispatch(setCartLoading(false))
    dispatch(setInfoActionButton(false));
    dispatch(setError(err))
    dispatch(handleError(err, navigate))
  }
}