import { Box } from '@mui/material'
import styled from 'styled-components'
import SearchIcon from '../../images/search.svg'
import CloseIcon from '../../images/close-icon.svg'
import { useEffect, useState } from 'react'
import { getSearchProducts, setCurrentSearchValue } from '../../store/product/productSlice'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import style from '../Keyboard/keyboard.module.css'
import KeyboardBasic from '../Keyboard/KeyboardBasic'
import { useTranslation } from 'react-i18next'

export const SearchInput = styled('input')`
  width: 100%;
  margin-right: 25px;
  background: transparent;
  outline: none;
  border: none;
  font-size: 32px;
  color: #ffffff;

  ::placeholder {
    color: #ffffff;
  }
`

export const Search = () => {
  const dispatch = useAppDispatch()
  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false)
  const currentId = useAppSelector(state => state.product.id)
  const currenSearchValue = useAppSelector(state => state.product.currentSearchValue)
  const { t } = useTranslation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(getSearchProducts(currenSearchValue, currentId))
    }, 1500)
    if (!isKeyboardOpen) return clearTimeout(timeout)
    return () => clearTimeout(timeout)
  }, [currenSearchValue])

  const onChange = (e: any) => {
    const text = e.target.value
    dispatch(setCurrentSearchValue(text))
  }

  const clearSearchInput = () => {
    dispatch(setCurrentSearchValue(''))
    dispatch(getSearchProducts('', currentId))
  }
  const openKeyboard = () => setIsKeyboardOpen(true)

  const handleBlur = (event: any) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      if (isKeyboardOpen) {
        setIsKeyboardOpen(false)
      }
    }
  }

  const renderClearInput = () => {
    if (!currenSearchValue) return null
    return <img onClick={clearSearchInput} src={CloseIcon} alt={'Close'}/>
  }

  return (
     <>
       <div onBlur={handleBlur}>
         <Box sx={styles.searchBox}>
           <img src={SearchIcon} alt={'Search'}/>
           <SearchInput
              placeholder={`${t('header.search')}`}
              value={currenSearchValue}
              onChange={onChange}
              onClick={openKeyboard}
           />
           {renderClearInput()}
         </Box>
         {isKeyboardOpen && (
            <div className={style.mainKeyboardContainer}>
              <Box sx={styles.keyboardBlock}>
                <KeyboardBasic/>
              </Box>
            </div>
         )}
       </div>
     </>

  )
}

const styles = {
  searchBox: {
    width: '100%',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    outline: 'none',
    border: '3px #667080 solid',
    borderRadius: '16px',
    fontSize: '32px',
    color: '#ffffff',
    'img:nth-of-type(1)': {
      marginLeft: '45px',
      marginRight: '25px',
    },
    '& > img:nth-of-type(2)': {
      marginRight: '45px',
      cursor: 'pointer',
    },
  },
  keyboardBlock: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '40px',
    marginBottom: '40px',
  },
  closeArea: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  closeText: {
    position: 'absolute',
    color: 'white',
    fontSize: '40px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}