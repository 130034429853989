import React, { useState } from 'react'
import style from './keyboard.module.css'
import Keyboard from './Keyboard'
import { KEYBOARDS, keyboards } from '../../enum/constant'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { setCurrentSearchValue } from '../../store/product/productSlice'


const KeyboardBasic = () => {
  const [keyboardCase, setKeyboardCase] = useState<KEYBOARDS>(KEYBOARDS.LOWERCASE)
  const currenSearchValue = useAppSelector(state => state.product.currentSearchValue)
  const dispatch = useAppDispatch()

  const setNewTargetValue = (value: string) => {
    if (value !== KEYBOARDS.UPPERCASE && value !== KEYBOARDS.LOWERCASE) {
      dispatch(setCurrentSearchValue(value))
    } else {
      setKeyboardCase(value)
    }
  }

  return (
     <div className={style.keyboardContainer}>
       <div className={style.keyboardLetter}>
         <Keyboard
            keysArray={keyboards[keyboardCase]}
            targetValue={currenSearchValue}
            newTargetValue={setNewTargetValue}
         />
       </div>
       <div className={style.keyboardNumpad}>
         <Keyboard
            keysArray={keyboards.numpad}
            targetValue={currenSearchValue}
            newTargetValue={setNewTargetValue}
         />
       </div>
     </div>
  )
}

export default KeyboardBasic