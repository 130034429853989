import { Box, Typography } from '@mui/material'
import { makeStyles } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import style from './Patience.module.css'
import { UniversalButton } from '../../components/Button'
import { path } from '../../enum/constant'
import { printReceipt } from '../../store/cart/cartSlice'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { clearRootItem } from '../../store/product/productSlice'
import { clearAddressList } from '../../store/crypto/cryptoSlice'
import { t } from 'i18next'

export const Patience = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const goToWaitTransaction = () => {
    navigate(-1)
  }
  const gotoChangePayment = () => {
    dispatch(clearAddressList())
    navigate(path.payment())
  }
  const goToHome = () => {
    dispatch(clearAddressList())
    dispatch(clearRootItem())
    navigate(path.home())
  }
  const goPrintReceipt = async () => {
    await dispatch(printReceipt(navigate))
    navigate(path.printCryptoReceipt())
  }

  return (
     <Box sx={styles.container}>
       <Box sx={styles.box} textAlign={'center'}>
         <Typography
            variant={'h1'}
            fontSize={80}
            fontWeight={700}
            color={'white'}
            mb={'88px'}
         >
           {t("crypto.patience.title")}
         </Typography>
         <Box className={style.buttonBlock} marginBottom={'64px'}>
           <Box marginBottom={'24px'}>
             <Typography
                variant={'h2'}
                fontSize={48}
                color={'white'}
             >
               {t("crypto.patience.blockOne")}
             </Typography>
           </Box>
           <Box className={style.buttonBox}>
             <UniversalButton
                children={t("crypto.buttons.wait")}
                colorText={'#1C1C1C'}
                fontSize={'28px'}
                background={'#07F285'}
                fontWeight={700}
                width={'380px'}
                onClick={goToWaitTransaction}
             />
             <Typography
                variant={'h2'}
                fontSize={48}
                color={'white'}
                marginX={'24px'}
             >
               {t("crypto.patience.or")}
             </Typography>
             <UniversalButton
                children={t("crypto.buttons.print")}
                colorText={'#FFFFFF'}
                fontSize={'28px'}
                background={'#667080'}
                fontWeight={700}
                width={'380px'}
                onClick={goPrintReceipt}
             />
           </Box>
         </Box>
         <Box className={style.buttonBlock}>
           <Box marginBottom={'24px'}>
             <Typography
                variant={'h2'}
                fontSize={48}
                color={'white'}
             >
               {t("crypto.patience.blockTwo")}
             </Typography>
           </Box>
           <Box className={style.buttonBox}>
             <UniversalButton
                children={t("crypto.buttons.change")}
                variant={'outlined'}
                colorText={'#FFFFFF'}
                fontSize={'28px'}
                background={'transparent'}
                fontWeight={700}
                width={'380px'}
                border={'3px solid #F7931A'}
                onClick={gotoChangePayment}
             />
             <Typography
                variant={'h2'}
                fontSize={48}
                color={'white'}
                marginX={'24px'}
             >
               {t("crypto.patience.or")}
             </Typography>
             <UniversalButton
                children={t("crypto.buttons.cancelTransaction")}
                variant={'outlined'}
                colorText={'#FFFFFF'}
                fontSize={'28px'}
                background={'transparent'}
                fontWeight={700}
                width={'380px'}
                border={'3px solid #F44545'}
                onClick={goToHome}
             />
           </Box>
         </Box>
       </Box>
     </Box>
  )
}


const styles = makeStyles({
  container: {
    background: '#111111',
    height: '100vh',
  },
  box: {
    height: '100%',
    padding: '40px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  box2: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerBox: {
    width: '100%',
    padding: '0 64px',
  },
})