import { CategoryItem, ProductItem } from '../../components/Box'
import { Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  clearRootItem,
  getElementFromCategory, getProduct,
  getSearchProducts,
  setCurrentSearchValue,
} from '../../store/product/productSlice'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { setNavigateNext } from '../../store/navigation/navigationSlice'
import { addItemCart } from '../../store/cart/cartSlice'
import { getImage } from '../../helpers'
import { NOTIFICATION_TYPE, path, query } from '../../enum/constant'
import { Pagination } from '../../components/Pagination'
import { selectNotification } from '../../store/notification/selectors'
import SearchFail from '../../components/SearchFail/SearchFail'
import { Drawer } from '../../components/Drawer'
import { useResponsive } from '../../hooks/useResponsive'

const ONE_SIZE = 'onesize'

export const Categories = ({ id }: { id: number }) => {
  const dispatch = useAppDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()
  const location = useLocation()
  const data = useAppSelector(state => state.product.root_items)
  const currentSearchValue = useAppSelector(state => state.product.currentSearchValue)
  const { notificationType } = useAppSelector(selectNotification)
  const isSearchFail = notificationType === NOTIFICATION_TYPE.NOT_FOUND
  const [openDrawer, setOpenDrawer] = useState(false)
  const [size, setSize] = useState('')
  const [product, setProduct] = useState<any>({})
  const [load, setLoad] = useState<boolean>(false)
  const { isMinor } = useResponsive()


  useEffect(() => {
    (async () => {
      if (currentSearchValue) {
        await dispatch(getSearchProducts(currentSearchValue, id, currentPage))
      } else {
        await dispatch(getElementFromCategory(id, currentPage, navigate))
      }
    })()
  }, [id, currentPage])


  const handleOpenCategory = (idItem: string, name: string) => {
    const payload = {
      dataNavigate: { navigate, location },
      to: path.next(`category_${idItem}`),
      search: query.category(idItem, name),
      name: name,
    }
    dispatch(setCurrentSearchValue(''))
    dispatch(setNavigateNext(payload))
    dispatch(clearRootItem())
  }

  const handleOpenProduct = (idItem: string, name: string) => {
    const payload = {
      dataNavigate: { navigate, location },
      to: path.next(`product_${idItem}`),
      search: query.product(idItem, name),
      name: name,
    }
    dispatch(setCurrentSearchValue(''))
    dispatch(setNavigateNext(payload))
  }

  const getSize = () => {
    if (!product.variations) return
    if (size === '') return
    const sizeKeys = Object.keys(product.variations[0])
    const formatted = product.variations[0][sizeKeys[0]]
    let obj = null
    if (formatted) {
      obj = formatted.find((el: any) => el.value === size)
    }

    const newProduct = {
      ...product,
      id: obj?.product_item_id,
      size: obj?.value,
    }

    dispatch(addItemCart({ ...newProduct, count: 1 }))
    setSize('')
  }

  useEffect(() => {
    getSize()
  }, [size])

  const handleClick = async (e: any, value: any) => {
    e.stopPropagation()
    setLoad(true)
    try {
      const res = await dispatch(getProduct(value.id, navigate))
      setProduct(res)
      const sizeKeys = Object.keys(res.variations[0])
      const oneSize = res.variations[0][sizeKeys[0]].filter((el: any) => el.value !== ONE_SIZE)
      if (!oneSize?.length) {
        setSize(ONE_SIZE)
        return
      }
      setOpenDrawer(true)
    } finally {
      setLoad(false)
    }
  }

  const getThingSize = (size: string) => {
    if (size) return setSize(size)
  }


  const renderCategory = () => {
    return data?.map((value) => {
      if (value.entity_type === 'category') return (
        <CategoryItem
          key={`category_${value.id}`}
          onClick={() => handleOpenCategory(value.id, value.name)}
          src={getImage(value.image.url)}
          name={value.name}/>
      )
    })
  }
  const renderProduct = () => {
    return data?.map((value) => {
      if (value.entity_type === 'product') return (
        <ProductItem
          key={`product_${value.id}`}
          onClick={() => handleOpenProduct(value.id, value.name)}
          addToCard={(e: any) => handleClick(e, value)}
          src={getImage(value.image.url)}
          name={value.name}
          price={value.price}/>
      )
    })
  }

  const renderAllItems = () => {
    if (isSearchFail) return <SearchFail />
    return (
       <>
         {renderCategory()}
         {renderProduct()}
       </>
    )
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box sx={isMinor ? styles.minorContainer : { display: 'flex', flexWrap: 'wrap', gap: '32px', marginTop: '65px' }}>
        {renderAllItems()}
      </Box>
      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage}  />
      <Drawer
         variations={product?.variations}
         size={size}
         getSize={getThingSize}
         open={openDrawer}
         onClose={() => setOpenDrawer(false)}
      />
    </Box>
  )
}

const styles = {
  minorContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    justifyItems: 'center',
    gap: '32px',
    marginTop: '65px',
  }
}