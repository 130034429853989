import React, { useEffect, useState } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import Terminal from '../../images/terminal.png'
import { BackButton, BuyButton } from '../../components/Button'
import ArrowLeft from '../../images/arrow-left.svg'
import CashIcon from '../../images/cash-icon.svg'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { cashDisable, cashEnable, getExecute } from '../../store/kiosk/kioskSlice'
import { printReceipt } from '../../store/cart/cartSlice'
import useWebSocket from '../../hooks/useWebSocket'
import { path } from '../../enum/constant'
import i18n, { t } from 'i18next'
import Loader from '../../components/Shared/Loader/Loader'

const moneyCount = ['$1,', '$5,', '$10,', '$20,', '$50,', '$100']

const Cash = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const total = useAppSelector(state => state.cart.total)
  const cashLoading = useAppSelector(state => state.kiosk.cashLoading)
  const [effect, setEffect] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const { data } = useWebSocket()

  const contactPhone = localStorage.getItem('contact_phone_number')

  const remainsToBePaid = () => {
    if (!data) return total.toLocaleString('en-US', { minimumFractionDigits: 2 })
    let money = total - data.total
    if (total <= data.total) return '0'
    return money.toLocaleString('en-US', { minimumFractionDigits: 2 })
  }

  const insertedMoney = () => {
    if (!data) return '0'
    return data.total.toLocaleString('en-US', { minimumFractionDigits: 2 })
  }

  const goBack = () => navigate(-1)

  const buyProducts = () => {
    setDisabled(true)
    dispatch(getExecute())
    navigate(path.thank())
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (data)
        setEffect(false)
    }, 500)
    if (!effect && data) return setEffect(true)
    return () => clearTimeout(timeout)
  }, [data])

  useEffect(() => {
    dispatch(cashEnable(navigate))
    return () => dispatch(cashDisable())
  }, [])

  const renderBackButton = () => {
    if (!data) return (
       <BackButton onClick={goBack}>
         <img src={ArrowLeft} alt={'Back'}/>
         {t("crypto.buttons.back")}
       </BackButton>
    )
  }

  const renderBuyButton = () => {
    if (data && data.total >= total) {
      return (
        <BuyButton disabled={disabled} onClick={buyProducts} sx={{ width: '160px!important' }} children={t("products.buttons.buy")}/>
      )
    }
  }

  if (cashLoading) return <Loader/>


  return (
     <Box sx={styles.container}>
       <Box sx={styles.blockContainer}>
         <Box sx={styles.blockOne}>
           <img src={Terminal} alt="terminal"/>
           <Box sx={styles.animate}/>
         </Box>
         <Box sx={styles.blockTwo}>
           <Box sx={styles.cashTxt}>
             <Typography fontSize={i18n.language !== 'en_US' ? '36px' : '40px' } fontWeight={700} color={'white'} lineHeight={'56px'}>
               {t("cash.title")}
             </Typography>
             <img src={CashIcon} alt="cash"/>
           </Box>
           <Box sx={styles.bill}>
             <Box>
               <Typography marginBottom={'10px'} fontSize={'28px'} fontWeight={400} color={'#E1E1E180'}>{t("cash.bills")}</Typography>
               {moneyCount.map(el => <Typography key={el} lineHeight={'48px'} component={'span'}
                                                 sx={styles.moneyCount}>{el} </Typography>)}
               <Divider style={{ marginTop: '37px' }} sx={styles.divider}/>
             </Box>
             <Box marginTop={'50px'}>
               <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                 <Typography fontSize={'32px'} fontWeight={400} color={'#E1E1E180'}>{t("cash.total")}</Typography>
                 <Typography fontSize={'56px'} fontWeight={700} lineHeight={'unset'}
                             color={'white'}>$ {total.toLocaleString('en-US', { minimumFractionDigits: 2 })}</Typography>
               </Box>
               <Divider style={{ marginTop: '60px' }} sx={styles.divider}/>
             </Box>
             <Box marginTop={'40px'}>
               <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                 <Typography marginBottom={'10px'} fontSize={'28px'} fontWeight={400}
                             color={'#E1E1E180'}>{t("cash.inserted")}</Typography>
                 <Typography sx={effect ? styles.blinkGreen : { '': '' }} lineHeight={'56px'} fontSize={'40px'}
                             fontWeight={700}
                             color={'white'}>$ {insertedMoney()}</Typography>
               </Box>
               <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '32px' }}>
                 <Typography marginBottom={'10px'} fontSize={'28px'} fontWeight={400}
                             color={'#E1E1E180'}>{t("cash.remains")}</Typography>
                 <Typography sx={effect ? styles.blinkRed : { '': '' }} fontSize={'40px'} fontWeight={700}
                             color={'white'}>$ {remainsToBePaid()}</Typography>
               </Box>
               <Divider style={{ marginTop: '45px' }} sx={styles.divider}/>
             </Box>
             <Box marginTop={'40px'}>
               <Typography fontSize={'24px'} fontWeight={400} color={'#E1E1E180'}>{t("cash.telInfo")} <span
                    style={{ fontSize: '32px', fontWeight: '600', color: 'white' }}>{contactPhone && contactPhone}</span></Typography>
             </Box>
           </Box>
         </Box>
       </Box>
       <Box sx={!data ? styles.cashFooter : { justifyContent: 'flex-end', display: 'flex' }}>
         {renderBackButton()}
         {renderBuyButton()}
       </Box>
     </Box>
  )
}

const styles = {
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '32px',
  },
  blockContainer: {
    display: 'flex',
    gap: '32px',
  },
  blockOne: {
    position: 'relative',
    background: '#1C1C1C',
    border: '3px solid #667080',
    borderRadius: '16px',
    width: '330px',
    textAlign: 'center',
    'img': {
      marginTop: '80px',
    },
  },
  blockTwo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '35px',
  },
  cashFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cashTxt: {
    display: 'flex',
    background: '#1C1C1C',
    border: '3px solid #667080',
    borderRadius: '16px',
    padding: '32px 80px 32px 40px',
  },
  bill: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    background: '#1C1C1C',
    border: '3px solid #667080',
    borderRadius: '16px',
    padding: '34px 40px 32px 40px',
  },
  moneyCount: {
    color: 'white',
    fontWeight: '500',
    fontSize: '40px',
  },
  divider: {
    border: '1px solid #858585',
  },
  animate: {
    position: 'absolute',
    width: '55px',
    height: '27px',
    border: '4px solid #1185ff',
    borderRadius: '3px',
    right: '196px',
    top: '544px',
    animation: 'blinking 1s infinite',
  },
  blinkGreen: {
    color: 'green',
    animation: 'blinking 1s infinite',
  },
  blinkRed: {
    color: 'red',
    animation: 'blinking 1s infinite',
  },
}

export default Cash