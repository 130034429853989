import { Box, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { useEffect } from 'react'
import { updateTotal } from '../../store/cart/cartSlice'
import { CartItem } from '../../components/Box'
import { getImage } from '../../helpers'
import { Pagination } from '../../components/Pagination'
import usePagination from '../../hooks/usePagination'
import { t } from 'i18next'
import { useResponsive } from '../../hooks/useResponsive'

export const Cart = () => {
  const dispatch = useAppDispatch()
  const { cart, total, length } = useAppSelector(state => state.cart)

  const items = Object.values(cart)
  const { isMinor } = useResponsive()

  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    totalPages,
  } = usePagination({
    contentPerPage: 3,
    count: length,
  })

  useEffect(() => {
    dispatch(updateTotal())
  }, [])

  const render = () => {
    if (!items.length) return <Typography variant={'h1'} sx={styles.empty}>{t("cart.empty")}</Typography>
    return (
       <Box style={isMinor ? { marginTop: '20px' } : { marginTop: '56px' }} sx={styles.itemsBox}>
         {items.slice(firstContentIndex, lastContentIndex)
            .map((value, index) => {
              return <CartItem key={value.id} {...value} src={getImage(value.image.url)}/>
            })}
       </Box>
    )
  }

  return (
     <Box sx={styles.container}>
       {render()}
       <Box marginBottom={isMinor ? '20px' : "80px"}>
         <Pagination
            page={page}
            totalPages={totalPages}
            nextPage={nextPage}
            prevPage={prevPage}
         />
       </Box>
     </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    height: '100%',
  },
  itemsBox: {
    marginTop: '56px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  totalBox: {
    color: '#ffffff',
    fontSize: '40px',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'space-between',
  },
  confirmBox: {
    display: 'flex',
    justifyContent: 'end',
  },
  empty: {
    width: '100%',
    textAlign: 'center',
    color: 'white',
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}