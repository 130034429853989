import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationEN from './locales/en/translation.json'
import translationSP from './locales/sp/translation.json'

const resources = {
  en_US: {
    translation: translationEN,
  },
  es_MX: {
    translation: translationSP,
  },
}

i18n
   .use(Backend)
   .use(initReactI18next)
   .use(LanguageDetector)
   .init({
     detection: {
       order: ['querystring'],
       lookupQuerystring: 'locale',
     },
     resources,
     fallbackLng: 'en_US',
     debug: false,
     interpolation: {
       escapeValue: false,
     },
   })

export default i18n
