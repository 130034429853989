import ltc from '../images/ltc.png'
import btc from '../images/btc.png'
import bch from '../images/bch.png'
import rvn from '../images/rvn.png'
import dash from '../images/dash.png'
import btc_t from '../images/btc_t.png'
import doge from '../images/doge.png'
import usdt from '../images/usdt.png'
import eth from '../images/eth.png'

export const path = {
  home: () => '/',
  cart: () => '/cart',
  next: (id = ':id') => `/${id}`,
  product: (id = ':id') => `${id}`,
  counter: () => `/counter`,
  thank: () => '/thank',
  error: () => '/error',
  cash: () => '/cash',
  payment: () => '/payment',
  phonePage: () => '/phonePage',
  codePage: () => '/codePage',
  smsPhonePage: () => '/smsPhonePage',
  coins: () => '/coins',
  cryptoInfo: () => '/cryptoInfo',
  cryptoCoins: () => '/cryptoCoins',
  cryptoWhoops: () => '/cryptoWhoops',
  cryptoThank: () => '/cryptoThank',
  cryptoPatience: () => '/cryptoPatience',
  qrCodeReady: () => '/qrCodeReady',
  printCryptoReceipt: () => '/printCryptoReceipt',
  printCounterReceipt: () => '/printCounterReceipt',
  scanQr: () => '/scanQr',
}

export const query = {
  category: (id: string, label: string) => `?category_${id}=${label}`,
  product: (id: string, label: string) => `?product_${id}=${label}`,
}

export const keyboards = {
  keyboardLowercase: [
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'Delete'],
    ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', '-', '_'],
    ['Uppercase', 'z', 'x', 'c', 'v', 'b', 'n', 'm', '.', '@', '+'],
  ],
  keyboardUppercase: [
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'Delete'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', '-', '_'],
    ['Lowercase', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '.', '@', '+'],
  ],
  numpad: [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9', '0'],
  ],
  numpadWithControls: [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['Clear', '0', 'Delete'],
  ],
}


export enum KEYBOARDS {
  LOWERCASE = 'keyboardLowercase',
  UPPERCASE = 'keyboardUppercase',
}


export const NOTIFICATION_TYPE = {
  NOT_FOUND: 'not-found',
  FOUND: 'found',
}

export const phoneValueLength = 13
export const authCodeLength = 5
export const phoneInitialValue = '+'

export const transactionTimeout = 60000


export enum PAYMENT_METHOD {
  CASH = 'cash',
  CARD = 'card',
  CRYPTO = 'crypto',
  COUNTER = 'counter',
}


export interface Coins {
  coin: string,
  sell_price: string,
}


export enum AVAILABLE_COINS_NAME {
  ltc = 'Litecoin',
  btc = 'Bitcoin',
  bch = 'Bitcoin Cash',
  rvn = 'Ravencoin',
  dash = 'Dash',
  btc_t = 'Bitcoin Test',
  doge = 'Dogecoin',
  usdt = 'Tether token',
  eth = 'Ether',
}

export type AvailableCoinsType = {
  name: AVAILABLE_COINS_NAME,
  token: string,
  icon: string
}

export type CoinsType = {
  coins: AvailableCoinsType
  price: string
}

export const AVAILABLE_COINS: Record<string, AvailableCoinsType> = {
  ltc: { name: AVAILABLE_COINS_NAME.ltc, token: 'ltc', icon: ltc },
  btc: { name: AVAILABLE_COINS_NAME.btc, token: 'btc', icon: btc },
  bch: { name: AVAILABLE_COINS_NAME.bch, token: 'bch', icon: bch },
  rvn: { name: AVAILABLE_COINS_NAME.rvn, token: 'rvn', icon: rvn },
  dash: { name: AVAILABLE_COINS_NAME.dash, token: 'dash', icon: dash },
  btc_t: { name: AVAILABLE_COINS_NAME.btc_t, token: 'btc_t', icon: btc_t },
  doge: { name: AVAILABLE_COINS_NAME.doge, token: 'doge', icon: doge },
  usdt: { name: AVAILABLE_COINS_NAME.usdt, token: 'usdt', icon: usdt },
  eth: { name: AVAILABLE_COINS_NAME.eth, token: 'eth', icon: eth },
}

export type addressListType = {
  address: string,
  amount: string,
  coins_amount: string,
  payment_url: string,
}

export type cryptoTransactionTypes = {
  cash_amount: number
  coin: string
  coins_amount: number
  order_id: string
  rate: number
  received_cash_amount: number
  received_coins_amount: number
  rejected_cash_amount: number
  rejected_coins_amount: number
  requested_cash_amount: number
  token: string
  waiting_confirmation: boolean
}

export const mediaQueries = {
  main: 1080,
  minor: 1280,
};