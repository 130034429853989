import React from 'react'
import KeyboardKey from './KeyboardKey'
import styles from './keyboard.module.css'
import { phoneInitialValue } from '../../enum/constant'

type KeyboardProps = {
  keysArray?: any,
  target?: any,
  targetValue?: any,
  maxLength?: any,
  newTargetValue?: any,
  availableArray?: boolean

}

const Keyboard = (
   {
     keysArray,
     target,
     targetValue,
     maxLength,
     newTargetValue,
     availableArray = false
   }: KeyboardProps) => {

  const sendNewTargetValue = (dataFromKey: string) => {
    newTargetValue(setNewValue(dataFromKey, targetValue, maxLength))

    function setNewValue(key: string, currentValue: any, maxLength = false) {
      if (key !== 'Clear' && key !== 'Delete' && key !== 'Uppercase' && key !== 'Lowercase') {
        if (maxLength) {
          if (currentValue && currentValue.length === maxLength) {
            return currentValue
          } else {
            return currentValue ? currentValue + '' + key : key
          }
        } else {
          return currentValue ? currentValue + '' + key : key
        }

      } else if (key === 'Clear') {
        return false
      } else if (key === 'Delete') {
        if(target === 'phone'){
          if(currentValue && currentValue.length > phoneInitialValue.length){
            return currentValue.substring(0, currentValue.length - 1);
          }else{
            return false;
          }
        }
        if (currentValue && currentValue.length > 0) {
          return currentValue.substring(0, currentValue.length - 1)
        } else {
          return ''
        }
      } else if (key === 'Uppercase') {
        return 'keyboardUppercase'
      } else if (key === 'Lowercase') {
        return 'keyboardLowercase'
      }
    }
  }


  return (
     <div className={`${styles.keyboard} float-container`}>
       {keysArray.map((row: any, i: any) => (
          <div key={'keyboard-row-' + i} className={`${styles.keyboardRow} float-container`}>
            {row.map((key: any, i: any) => (
               <KeyboardKey target={target} availableArray={availableArray} sendKeyValue={sendNewTargetValue}
                            key={key + ' ' + i} value={key}/>
            ))}
          </div>
       ))}
     </div>
  )
}

export default Keyboard;