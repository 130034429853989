import { Box, Typography } from '@mui/material'
import { makeStyles } from '../../hooks'
import { BackButton, ExitButton } from '../../components/Button'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { closeSession } from '../../store/kiosk/kioskSlice'
import { useNavigate } from 'react-router-dom'
import React, {useCallback, useEffect, useState} from 'react'
import { getTextInfoPage } from '../../helpers'
import PaymentFooter from '../../components/PaymentFooter/PaymentFooter'
import style from './Info.module.css'
import { path } from '../../enum/constant'
import {selectAvailableCoin, selectCryptoTransaction, selectPhoneNum, selectToken} from '../../store/crypto/selectors'
import ArrowLeft from '../../images/arrow-left.svg'
import {t, use} from 'i18next'
import {ViewWrapper} from "../../components/ViewWrapper/ViewWrapper";
import {InfoActionButtons, successType} from "./InfoActionButtons";
import {setInfoActionButton} from "../../store/cart/cartSlice";

export const Info = ({ icon, type }: { icon: any, type: string }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const availableCoin = useAppSelector(selectAvailableCoin)
  const { rejected_coins_amount, cash_amount, coins_amount } = useAppSelector(selectCryptoTransaction)
  const coin = useAppSelector(selectToken)
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [viewButtons, SetViewButtons] = useState(false)
  const infoActionButtonPressed = useAppSelector(state => state.cart.infoActionButtonPressed)

  const handleExit = () => {
    dispatch(closeSession(navigate))
  }

  useEffect(() => {
    const [t, s] = getTextInfoPage(type, +availableCoin.max_amount_for_zero_confirmation, cash_amount, coins_amount, coin)
    setTitle(t)
    setSubTitle(s)
  }, [])

    // uses for hide action buttons after the print receipt pressed or send sms after phone number input redirected
   useEffect(() => {
       if(infoActionButtonPressed) {
           SetViewButtons(false);
       }
   }, [infoActionButtonPressed]);

    useEffect(() => {
        if (['printCryptoReceipt', 'printCounterReceipt', 'cryptoThank', 'thank', 'cryptoWhoops'].includes(type)) {
            SetViewButtons(true);
        } else {
            SetViewButtons(false);
        }
    }, [type]);

  const handleClick = () => {
    navigate(path.qrCodeReady())
  }

  const goBack = () => navigate(-1)

    const onActionSuccess = useCallback((actionType: successType) => {
        SetViewButtons(false);
        dispatch(setInfoActionButton(false));
        if (actionType === 'sms') {
            const [t, s] = getTextInfoPage('smsCryptoReceipt', +availableCoin.max_amount_for_zero_confirmation, cash_amount, coins_amount, coin)
            setTitle(t)
            setSubTitle(s)
        }
    }, [viewButtons])

  const renderFooter = () => {
    if (type === 'cryptoInfo') {
      return (
         <Box sx={styles.footerBox}>
           <PaymentFooter visible text={`${t("crypto.buttons.understand")}`} onClick={() => handleClick()}/>
         </Box>
      )
    }
    if (type === 'cryptoCoins') {
      return (
         <BackButton onClick={goBack}>
           <img src={ArrowLeft} alt={'Back'}/>
           {t("crypto.buttons.back")}
         </BackButton>
      )
    }

    return (
       <ExitButton
          colorText={'#1C1C1C'}
          fontSize={'28px'}
          background={'#07F285'}
          width={'159px'}
          onClick={handleExit}
       >
         {t("header.buttons.exit")}
       </ExitButton>
    )
  }

  const renderInfoBlock = () => {
    if (type === 'cryptoWhoops') {
      return (
         <Box className={style.infoBox}>
           <Typography
              variant={'h2'}
              fontSize={40}
              color={'white'}
              fontWeight={400}
           >
             {t("info.cryptoWhoops.textOne")} {t("info.cryptoWhoops.below").toUpperCase()} {t("info.cryptoWhoops.amount")} <br/> <span
              className={style.priceCrypto}>{rejected_coins_amount} {coin.toUpperCase()}</span> {t("info.cryptoWhoops.sendBack")}
           </Typography>
         </Box>
      )
    }
    if (type === 'cryptoThank') {
      return (
         <Box className={style.infoBox}>
           <Typography
              variant={'h2'}
              fontSize={40}
              color={'white'}
              fontWeight={400}
           >
             {t("info.cryptoThank.textOne")} {t("info.cryptoThank.above").toUpperCase()} {t("info.cryptoThank.amount")} <br/> <span
              className={style.priceCrypto}>{rejected_coins_amount} {coin.toUpperCase()}</span> {t("info.cryptoThank.sendBack")}
           </Typography>
         </Box>
      )
    }
    if (type === 'cryptoCoins') {
      return (
         <Box className={style.infoBox}>
           <Typography
              variant={'h2'}
              fontSize={40}
              color={'white'}
              fontWeight={400}
           >
             {t("info.notAvailable")}
           </Typography>
         </Box>
      )
    }
    if (type === 'printCryptoReceipt') {
      return (
         <Box className={style.infoBox}>
           <Typography
              variant={'h2'}
              fontSize={40}
              color={'white'}
              fontWeight={400}
           >
             {t("info.sms")}
           </Typography>
         </Box>
      )
    }
    if (type === 'printCounterReceipt') {
        return <Box className={style.infoBox}>
            <Typography
                variant={'h2'}
                fontSize={40}
                color={'white'}
                fontWeight={400}
            >
                {t("info.counter")}
            </Typography>
        </Box>
    }
  }

  return (
     <Box sx={styles.container}>
       <Box sx={styles.box}>
         <Box sx={styles.box2} textAlign={'center'}>
           <img src={icon} alt="check"/>
           <Typography
              variant={'h1'}
              fontSize={80}
              fontWeight={700}
              margin={'85px 0 60px 0'}
              color={'white'}
           >
             {title}
           </Typography>
             <ViewWrapper enable={viewButtons}>
                 <InfoActionButtons onSuccess={onActionSuccess}/>
             </ViewWrapper>
           <Typography
              variant={'h2'}
              fontSize={48}
              color={'white'}
           >
               <ViewWrapper disable={viewButtons}>
                   {subTitle}
               </ViewWrapper>
           </Typography>
           {renderInfoBlock()}
         </Box>
         {renderFooter()}
       </Box>
     </Box>
  )
}


const styles = makeStyles({
  container: {
    background: '#111111',
    height: '100vh',
  },
  box: {
    height: '100%',
    padding: '40px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box2: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerBox: {
    width: '100%',
  },
})