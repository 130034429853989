import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from '../index'
import axios, { AxiosError } from 'axios'
import { handleError } from '../error/errorSlice'
import { NavigateFunction } from 'react-router'
import {
  AVAILABLE_COINS_NAME,
  AVAILABLE_COINS,
  CoinsType,
  Coins,
  addressListType,
  cryptoTransactionTypes,
} from '../../enum/constant'

type initialStateType = {
  phoneNumber: string,
  coins: CoinsType[],
  loading: boolean,
  addressList: addressListType,
  current_token: string,
  available_coin: {
    available: null | boolean,
    waiting_confirmation: null | boolean,
    max_amount_for_zero_confirmation: string,
  },
  cryptoTransaction: cryptoTransactionTypes,
}

const initialState: initialStateType = {
  phoneNumber: '',
  coins: [],
  loading: false,
  addressList: {
    address: '',
    amount: '',
    coins_amount: '',
    payment_url: '',
  },
  current_token: '',
  available_coin: {
    available: null,
    waiting_confirmation: null,
    max_amount_for_zero_confirmation: '',
  },
  cryptoTransaction: {
    cash_amount: 0,
    coin: '',
    coins_amount: 0,
    order_id: '',
    rate: 0,
    received_cash_amount: 0,
    received_coins_amount: 0,
    rejected_cash_amount: 0,
    rejected_coins_amount: 0,
    requested_cash_amount: 0,
    token: '',
    waiting_confirmation: false,
  }
}

const cryptoSlice = createSlice({
  name: 'cryptoSlice',
  initialState,
  reducers: {
    setPhoneNumber(state, { payload }: { payload: string }) {
      state.phoneNumber = payload
    },
    setAvailableCoins(state, { payload }: { payload: CoinsType[] }) {
      state.coins = payload
    },
    setLoadingCoins(state, { payload }: { payload: boolean }) {
      state.loading = payload
    },
    setAddressList(state, { payload }: { payload: addressListType }) {
      state.addressList = payload
    },
    setInfoAvailableCoin(state, { payload }: { payload: any }) {
      state.available_coin = payload
    },
    setTokenName(state, { payload }: { payload: string }) {
      state.current_token = payload
    },
    setCryptoTransaction(state, { payload }: { payload: cryptoTransactionTypes }) {
      state.cryptoTransaction = payload
    },
    clearAddressList: (state) => {
      state.addressList = initialState.addressList
    },
  }
})

export const { setPhoneNumber, setAvailableCoins, setLoadingCoins, setAddressList, setInfoAvailableCoin, setTokenName, setCryptoTransaction, clearAddressList } = cryptoSlice.actions
export default cryptoSlice.reducer

export const sendVerificationCode = (tel: string) => async (dispatch: AppDispatch) => {
  dispatch(setPhoneNumber(tel))
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }
    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/session/send-verification-code/`, { phone_number: tel }, { headers })
    return res?.data
  } catch (err: AxiosError | any) {
    console.log(err)
  }
}

export const checkVerificationCode = (tel: string, authCode: string) => async (dispatch: AppDispatch) => {
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }

    const auth = {
      phone_number: tel,
      auth_code: authCode,
    }

    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/session/check-verification-code/`, { ...auth }, { headers })
    return res?.data?.status !== 'bad_code'
  } catch (err: AxiosError | any) {
    console.log(err)
  }
}


export const getAvailableCoins = (navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
  dispatch(setLoadingCoins(true))
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }

    const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/kiosk/get-available-coins/`, { headers })

    const mappedCoinsTypes: CoinsType[] = []
    res?.data.forEach((e: Coins) => {
      mappedCoinsTypes.push({
        coins: AVAILABLE_COINS[e.coin as keyof typeof AVAILABLE_COINS_NAME],
        price: e.sell_price,
      })
    })
    if (mappedCoinsTypes.length) dispatch(setAvailableCoins(mappedCoinsTypes ?? []))
    dispatch(setLoadingCoins(false))
  } catch (err: AxiosError | any) {
    dispatch(handleError(err, navigate))
    console.log(err)
  }
}

export const checkAvailableCoin = (coin: string, navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }

    const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/kiosk/check-coin-availability/`, {
      params: { coin },
      headers,
    })
    dispatch(setInfoAvailableCoin(res.data))
    dispatch(setTokenName(coin))
    return {
      available: res.data.available,
      waiting_confirmation: res.data.waiting_confirmation,
    }
  } catch (err: AxiosError | any) {
    dispatch(handleError(err, navigate))
    console.log(err)
  }
}

export const generateReceivingAddress = (navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
  dispatch(setLoadingCoins(true))
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }

    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/order/gen-receiving-address/`, {}, { headers })
    dispatch(setAddressList(res?.data))
    dispatch(setLoadingCoins(false))
  } catch (err: AxiosError | any) {
    dispatch(handleError(err, navigate))
    console.log(err)
  }
}