import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import GenerateQr from '../../components/GenerateQr/GenerateQr'
import PaymentFooter from '../../components/PaymentFooter/PaymentFooter'
import { useNavigate } from 'react-router-dom'
import { path, transactionTimeout } from '../../enum/constant'
import Spinner from '../../components/Shared/Spinner/Spinner'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { clearAddressList, generateReceivingAddress, setCryptoTransaction } from '../../store/crypto/cryptoSlice'
import { selectAddressList, selectCoinsLoading, selectToken } from '../../store/crypto/selectors'
import useWebSocket from '../../hooks/useWebSocket'
import Loader from '../../components/Shared/Loader/Loader'
import { printReceipt } from '../../store/cart/cartSlice'
import { clearRootItem } from '../../store/product/productSlice'
import { t } from 'i18next'


const ScanQr = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const loading = useAppSelector(selectCoinsLoading)
  const coin = useAppSelector(selectToken)

  const {
    amount,
    address,
    coins_amount,
  } = useAppSelector(selectAddressList)

  const { data } = useWebSocket()

  const buyProducts = async () => {
    await dispatch(printReceipt(navigate))
  }

  const renderCoinAmount = coins_amount + ' ' + coin.toUpperCase()

  useEffect(() => {
    if (address) return
    dispatch(generateReceivingAddress(navigate))
  }, [])

  useEffect(() => {
    if (data) {
      dispatch(setCryptoTransaction(data))
      if (data.waiting_confirmation) {
      } else if ((data.cash_amount >= data.requested_cash_amount) && !data.rejected_cash_amount) {
        navigate(path.thank())
      } else if ((data.cash_amount >= data.requested_cash_amount) && data.rejected_cash_amount) {
        navigate(path.cryptoThank())
      } else if (data.requested_cash_amount >= data.cash_amount && data.rejected_cash_amount) {
        navigate(path.cryptoWhoops())
      }
    }
  }, [data])

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(path.cryptoPatience())
    }, transactionTimeout)

    return () => clearTimeout(timeout)
  }, [])

  const cancelTransaction = () => {
    dispatch(clearAddressList())
    dispatch(clearRootItem())
    navigate(path.home())
  }

  if (loading) return <Loader/>

  return (
     <Box sx={styles.container}>
       <Box>
         <Typography
            lineHeight={'64px'}
            textAlign={'center'}
            fontWeight={700}
            fontSize={'56px'}
            color={'white'}
            mb={'30px'}
         >
           {t("crypto.scanQr.title")}
         </Typography>
         <Box sx={styles.qrBox}>
           <Box>
             <Box>
               <Typography color={'white'} mb={'10px'} fontSize={'40px'} lineHeight={'48px'}>{t("crypto.scanQr.coins")}</Typography>
               <Box sx={styles.boxCrypto} fontSize={'37px'} color={'white'} mb={'70px'}>
                 <Box component={'span'} fontWeight={700}>{renderCoinAmount}</Box> (${amount})
               </Box>
               <Box>
                 <Typography color={'white'} mb={'10px'} fontSize={'40px'} lineHeight={'48px'}>{t("crypto.scanQr.address")}</Typography>
                 <Box sx={styles.boxCrypto}>
                   <Typography fontSize={'37px'} color={'white'}>{address}</Typography>
                 </Box>
               </Box>
             </Box>
           </Box>
           <GenerateQr/>
         </Box>
         <Box>
           <Typography textAlign={'center'} mb={'30px'} color={'white'} fontSize={'40px'} lineHeight={'48px'}>{t("crypto.scanQr.waiting")}</Typography>
           <Spinner/>
           <Typography
              mb={'30px'}
              color={'white'}
              fontSize={'32px'}
              lineHeight={'36px'}
              textAlign={'center'}
              mt={'40px'}
           >
             {t("crypto.scanQr.transaction")}
           </Typography>
         </Box>
       </Box>
       <PaymentFooter cancel={cancelTransaction}/>
     </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    height: '100%',
  },
  qrBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '35px',
    alignItems: 'center',
  },
  boxCrypto: {
    border: '3px solid #667080',
    background: '#1C1C1C',
    borderRadius: '12px',
    maxWidth: '500px',
    wordWrap: 'break-word',
    width: 'max-content',
    padding: '10px',
  },
}

export default ScanQr