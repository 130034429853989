import { useEffect, useRef, useState } from 'react'

const useWebSocket = () => {

  const [data, setData] = useState<any>(null)
  const ws = useRef<null | any>(null)
  const sessionToken = localStorage.getItem('session_token')
  const socketUrl = `${process.env.REACT_APP_SERVER_URL}/events/${sessionToken}`

  useEffect(() => {
    ws.current = new WebSocket(`wss://${socketUrl.substring(8)}`)
    if (!ws.current) return
    ws.current.onopen = () => console.log('ws opened')
    ws.current.onclose = () => console.log('ws closed')

    ws.current.onmessage = (e: any) => setData(JSON.parse(e.data))

    return () => ws.current.close()
  }, [ws])

  return {
    data: data?.data
  }
}

export default useWebSocket