import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from '../index'


type initialStateType = {
  name: string
  back_url: string[]
}

const initialState: initialStateType = {
  name: '',
  back_url: [],
}

const navigationSlice = createSlice({
  name: 'navigationSlice',
  initialState,
  reducers: {
    setName: (state, { payload }: { payload: string }) => {
      state.name = payload
    },
    setBack: (state, { payload }: { payload: string[] }) => {
      state.back_url = payload
    },
  },
})

const { setName } = navigationSlice.actions
export default navigationSlice.reducer

export const setNavigateNext = (payload: any) => async (dispatch: AppDispatch) => {
  const { dataNavigate, to, name, search } = payload
  dispatch(setName(name))
  let url = (dataNavigate.location.pathname + to).replace('//', '/')
  let query = dataNavigate.location.search + search
  let queryArray = query.split('?').filter((x: any) => x)
  dataNavigate.navigate(url + '?' + queryArray.join('&'))
}
