import { Breadcrumbs as MUIBreadcrumbs, Link, Theme, Typography } from '@mui/material'
import HomeIcon from '../../images/home.svg'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FC } from 'react'
import { path } from '../../enum/constant'
import { setCurrentSearchValue } from '../../store/product/productSlice'
import { hideNotification } from '../../store/notification/notificationSlice'
import { useAppDispatch } from '../../hooks/redux'


const withRouter = (WrappedComponent: FC) => (props: any) => {
  let location = useLocation()
  let navigate = useNavigate()
  let params = useParams()

  // etc... other react-router-dom v6 hooks
  return (
     <WrappedComponent
        {...props}
        location={location}
        navigate={navigate}
        params={params}
        // etc...
     />
  )
}


const Breadcrumbs = (props: any) => {
  const { navigate, location: { pathname, search } } = props
  const searchParams = new URLSearchParams(search)
  const dispatch = useAppDispatch()


  const pathnames: any[] = pathname.split('/').filter((x: string) => x)

  const handleClick = (data: string, query: URLSearchParams) => {
    dispatch(setCurrentSearchValue(''))
    dispatch(hideNotification())
    const newUrl = pathnames.slice(0, pathnames.indexOf(data) + 1)
    pathnames.forEach(v => {
      if (!newUrl.includes(v))
        query.delete(v)
    })
    navigate(
       {
         pathname: `/${newUrl.join('/')}`,
         search: `?${query}`,
       })
  }

  const goHome = () => {
    dispatch(setCurrentSearchValue(''))
    dispatch(hideNotification())
    navigate(path.home())
  }

  const hidePathname = ['cart', 'cash', 'payment', 'phonePage', 'codePage', 'scanQr', 'qrCodeReady', 'cryptoPatience', 'coins', 'cryptoInfo', 'cryptoCoins', 'receive', 'give', 'smsPhonePage']


  return (
     <MUIBreadcrumbs sx={styles.box}>
       <img draggable={false} className={'cursor-pointer'} onClick={goHome} src={HomeIcon} alt={'Go to home'}/>
       {pathnames.map((value: string, index: number) => {
         if (hidePathname.includes(value)) return null
         const label = searchParams.get(value)
         if (pathnames.length - 1 === index) return <Typography key={value} sx={styles.text}>{label}</Typography>
         return <Link className={'cursor-pointer'} key={value} onClick={() => handleClick(value, searchParams)}
                      sx={styles.link}>{label}</Link>
       })}
     </MUIBreadcrumbs>
  )
}

const styles = ({
  box: {
    display: 'flex',
    border: (theme: Theme) => `3px solid ${theme.palette.secondary.main}`,
    paddingX: '16px',
    height: '64px',
    borderRadius: '16px',
    '.MuiBreadcrumbs-separator': {
      fontSize: '32px',
      color: (theme: Theme) => theme.palette.secondary.main,
      opacity: '50%',
      marginX: '16px',
    },
  },
  link: {
    color: '#E1E1E1!important',
    fontSize: '28px!important',
    fontWeight: '500!important',
    opacity: '50%!important',
    textDecoration: 'none!important',
    textOverflow: 'ellipsis!important',
  },
  text: {
    color: '#ffffff!important',
    fontSize: '28px!important',
    fontWeight: '500!important',
    opacity: '100!important',
    textOverflow: 'ellipsis',
    maxWidth: '500px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
})
export default withRouter(Breadcrumbs)