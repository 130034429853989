import { Box } from '@mui/material'
import { Header } from '../Header'
import { Outlet } from 'react-router-dom'
import { CartFooter } from '../Footer'
import { makeStyles } from '../../hooks'

export const Layout = () => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.box}>
        <Box sx={styles.box2} >
          <Header/>
          <Outlet/>
        </Box>
        <CartFooter />
      </Box>
    </Box>
  )
}


const styles = makeStyles({
  container: {
    background: '#111111',
    height: '100%',
  },
  box: {
    height: '100%',
    margin: '0 60px',
    padding: '32px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  box2: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }
})