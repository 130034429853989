import { createSlice } from '@reduxjs/toolkit'
import { path } from '../../enum/constant'
import { AppDispatch } from '../index'
import { NavigateFunction } from 'react-router'
import { AxiosError } from 'axios'
import { setStartSession } from '../kiosk/kioskSlice'

type initialStateType = {
  error_message: string
  error_code: string
}

const initialState: initialStateType = {
  error_message: '',
  error_code: '',
}

const errorSlice = createSlice({
  name: 'errorSlice',
  initialState,
  reducers: {
    setMessage(state, { payload }: { payload: string }) {
      state.error_message = payload
    },
    setCode(state, { payload }: { payload: string }) {
      state.error_code = payload
    },
  },
})

const { setMessage, setCode } = errorSlice.actions
export default errorSlice.reducer

export const handleError = (error: AxiosError | any, navigate: NavigateFunction) => (dispatch: AppDispatch) => {
  const message = error?.response?.data?.message || error?.response?.statusText || error?.message || "Error"
  const code = error?.response?.status || error?.code || 500
  const parseMessage = decodeURI(message)
  console.log(parseMessage, code)
  dispatch(setMessage(parseMessage))
  dispatch(setCode(code))
  dispatch(setStartSession())
  navigate(path.error())
}
