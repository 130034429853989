import { configureStore } from '@reduxjs/toolkit'
import cartSlice from './cart/cartSlice'
import productSlice from './product/productSlice'
import kioskSlice from './kiosk/kioskSlice'
import errorSlice from './error/errorSlice'
import notificationSlice from './notification/notificationSlice'
import cryptoSlice from './crypto/cryptoSlice'

export const store = configureStore({
  reducer: {
    cart: cartSlice,
    product: productSlice,
    kiosk: kioskSlice,
    error: errorSlice,
    notification: notificationSlice,
    crypto: cryptoSlice,
  }

})

// store.subscribe(() => console.log(store.getState()))

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type GetState = typeof store.getState

