import {Box, Drawer as MUIDrawer, Theme} from "@mui/material";
import {UniversalButton} from "../Button";

type DrawerProps = {
    variations: any;
    open: boolean;
    size: string;
    getSize: (size:string) => void;
    onClose: () => void;
}

export const Drawer = (props: DrawerProps) => {
    const { open, onClose, variations, getSize, size } = props
    if (!variations) return null

    const sizeKeys = Object.keys(variations[0])

    const getThingSize = (size: string) => {
        getSize(size)
        onClose()
    }

    return (
        <MUIDrawer
            anchor={'bottom'}
            open={open}
            onClose={onClose}
        >
            <Box
                sx={styles.container}
            >
                <Box sx={styles.box}>
                    {(props.variations[0][sizeKeys[0]] || []).map((v: any)=> <UniversalButton
                        children={v.value} //TODO: translate
                        variant={'outlined'}
                        colorText={size === v.value ? '#07F285' : '#FFFFFF'}
                        fontSize={'28px'}
                        background={'transparent'}
                        fontWeight={700}
                        width={'122px'}
                        disabled={!v.in_stock}
                        border={size === v.value ? '3px solid #07F285' : '3px solid #FFFFFF'}
                        onClick={() => getThingSize(v.value)}
                    />)}
                </Box>
            </Box>
        </MUIDrawer>
    )
}

const styles = ({
    container: {
        background: '#1C1C1C',
        borderTop: '3px solid #667080',
    },
    box: {
        display: 'flex',
        gap: '40px',
        flexWrap: 'wrap',
        margin: '64px 74px',
    }
})