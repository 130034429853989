/**
 * created 10.08.2023
 */
import React, {useCallback, useEffect} from 'react';
import {UniversalButton} from "../../components/Button";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {printReceipt, sendSMS, setInfoActionButton} from "../../store/cart/cartSlice";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {selectPhoneNum} from "../../store/crypto/selectors";
import { path } from '../../enum/constant'
import {Loading} from "../../components/Loading";
import Loader from "../../components/Shared/Loader/Loader";


export type successType = 'receipt' | 'sms'

interface InfoActionButtonsComponentInterface {
    onSuccess: (type: successType) => void;
}

function InfoActionButtonsComponent(props: InfoActionButtonsComponentInterface) {
    const dispatch = useAppDispatch()
    const {onSuccess} = props;
    const phoneNumber = useAppSelector(selectPhoneNum)
    const {infoActionButtonPressed, cartLoading} = useAppSelector(state => state.cart)
    const navigate = useNavigate()

    // uses after the phone number entered and came back to the page
    useEffect(() => {
        if (phoneNumber && infoActionButtonPressed){
            dispatch(sendSMS(phoneNumber, navigate, onSuccess))
        }
    }, [infoActionButtonPressed, phoneNumber, onSuccess]);

    const onPrintReceipt = useCallback(() => {
        dispatch(printReceipt(navigate, onSuccess))
    }, [onSuccess])

    const onSendSMS = useCallback(() => {
        if (!phoneNumber) {
            navigate(path.smsPhonePage())
        } else {
            dispatch(sendSMS(phoneNumber, navigate, onSuccess))
        }
    }, [onSuccess])

    const { t } = useTranslation()
    return <div style={styles.container}>
        {cartLoading ?  <Loader size={50}/> : (
            <>
                <UniversalButton
                    onClick={onPrintReceipt}
                    background={'none'}
                    width={'380px'}
                    variant={'outlined'}
                    border={'3px #F7931A solid'}
                >
                    {t('crypto.buttons.print')}
                </UniversalButton>
                <Typography variant={'h2'} color={'#fff'}>or</Typography>
                <UniversalButton
                    onClick={onSendSMS}
                    width={'380px'}
                    background={'none'}
                    variant={'outlined'}
                    border={'3px #07F285 solid'}
                    colorText={'#fff'}
                >
                    {t('crypto.buttons.sendSMS')}
                </UniversalButton>
            </>
        )}

    </div>;
}

export const InfoActionButtons = InfoActionButtonsComponent;

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    }
}