import React from 'react'
import { indexOf } from 'underscore'
import styles from './keyboard.module.css';
import DeleteIcon from '../../images/delete-icon.svg'
import ShiftIcon from '../../images/shift-icon.svg'

type KeyboardKeyProps = {
    target?: any,
    value?: string,
    sendKeyValue?: any,
    availableArray?: any,
}

const KeyboardKey = ({ target, value, sendKeyValue, availableArray }: KeyboardKeyProps) => {

    const handleKeyClick = () => {
        sendKeyValue(value)
    }

   const isButtonDisabled = () => {
        if (!availableArray) return false;
        return indexOf(availableArray, value) === -1
    }

    switch(value){
        case 'Delete':
            return(<button onClick={handleKeyClick} className={`${styles.keyboardButton} ${styles.keyboardButtonDelete}`} value={value}>
              <img src={DeleteIcon} alt="arrow"/></button>);
        case 'Clear':
            return(<button onClick={handleKeyClick} className={`${styles.keyboardButton} keyboard-button--clear`} value={value}>C</button>);
        case 'Uppercase':
            return(<button onClick={handleKeyClick} className={`${styles.keyboardButton} keyboard-button--uppercase`} value={value}>
              <img src={ShiftIcon} alt="shift"/></button>);
        case 'Lowercase':
            return(<button onClick={handleKeyClick} className={`${styles.keyboardButton} keyboard-button--lowercase`} value={value}>
              <img src={ShiftIcon} alt="shift"/>
            </button>);
        default:
            return (<button onClick={handleKeyClick} className={`${styles.keyboardButton}`} disabled={isButtonDisabled()} value={value}>{value}</button>);
    }
}
export default KeyboardKey;