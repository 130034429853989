import React, { useEffect, useRef } from 'react'
import QRCodeStyling from 'qr-code-styling'
import { Box } from '@mui/material'
import style from './GenerateQr.module.css'
import { useAppSelector } from '../../hooks/redux'
import { selectAddressList } from '../../store/crypto/selectors'

const qrCodeEx = new QRCodeStyling({
  width: 400,
  height: 400,
  margin: 25,
  dotsOptions: {
    color: 'black',
    type: 'square',
  },
})

const GenerateQr = () => {
  const { payment_url: url } = useAppSelector(selectAddressList)
  const ref = useRef<any>(null)

  useEffect(() => {
    qrCodeEx.append(ref.current)
  }, [])

  useEffect(() => {
    qrCodeEx.update({
      data: url,
    })
  }, [url])

  return (
     <Box className={style.qr} ref={ref}/>
  )
}

export default GenerateQr