import { Box } from '@mui/material'
import { ArrowButton } from '../Button'
import ArrowLeft from '../../images/arrow-left.svg'
import ArrowRight from '../../images/arrow-right.png'
import { useAppSelector } from '../../hooks/redux'
import { useLocation } from 'react-router-dom'
import { path } from '../../enum/constant'

type PaginationProps = {
  currentPage?: number,
  setCurrentPage?: any,
  nextPage?: () => void,
  prevPage?: () => void,
  page?: number,
  totalPages?: number,
}

export const Pagination = (
   {
     currentPage,
     setCurrentPage,
     nextPage,
     prevPage,
     page,
     totalPages,
   }: PaginationProps) => {
  const { maxPage } = useAppSelector(state => state.product)
  const { pathname } = useLocation()

  const handleNext = () => {
    if (currentPage && currentPage < maxPage)
      setCurrentPage(currentPage + 1)
  }

  const handlePrev = () => {
    if (currentPage && currentPage > 1)
      setCurrentPage(currentPage - 1)
  }

  if (pathname === path.cart()) {
    if (!totalPages || totalPages < 2) return null
    return (
       <Box sx={styles.paginationBox}>
         <Box>
           <ArrowButton onClick={prevPage}>
             <img draggable={false} src={ArrowLeft} alt="prev"/></ArrowButton>
         </Box>
         <Box sx={styles.paginationText}>{page} of {totalPages}</Box>
         <Box>
           <ArrowButton onClick={nextPage}>
             <img draggable={false} src={ArrowRight} alt="next"/>
           </ArrowButton>
         </Box>
       </Box>
    )
  }

  if (maxPage < 2) return null

  return (
     <Box sx={styles.paginationBox}>
       <Box><ArrowButton onClick={() => handlePrev()}><img draggable={false} src={ArrowLeft} alt="prev"/></ArrowButton></Box>
       <Box sx={styles.paginationText}>{currentPage} of {maxPage}</Box>
       <Box><ArrowButton onClick={() => handleNext()}><img draggable={false} src={ArrowRight} alt="next"/></ArrowButton></Box>
     </Box>
  )
}

const styles = ({
  paginationBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paginationText: {
    fontSize: '32px',
    fontWeight: '600',
    color: '#E1E1E1',
  },
})