import React from 'react'
import { Box, CircularProgress } from '@mui/material'

interface LoaderInterface {
  size?: number;
}

const Loader = (props: LoaderInterface) => {
  const {size = 200} = props;
  return (
     <Box sx={style.container}><CircularProgress size={`${size}px`}/></Box>
  )
}

const style = {
  container: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center"
  }
}

export default Loader