/**
 * created 10.08.2023
 */
import React, {JSXElementConstructor, ReactElement} from 'react';

interface ViewWrapperComponentInterface {
    enable?: boolean;
    disable?: boolean;
    children: any;
    dontRemove?: boolean;
}

function ViewWrapperComponent(props: ViewWrapperComponentInterface): ReactElement {
    const {enable, disable, children, dontRemove = false} = props;

    const isShow = (typeof enable === 'boolean' && enable) || (typeof disable === 'boolean' && !disable);

    if (dontRemove) {
        return <div style={!isShow ? styles.hide : undefined}>{children}</div>
    } else {
        return isShow ? children : <></>;
    }

}

export const ViewWrapper = ViewWrapperComponent;


const styles = {
    hide: {
        display: 'none',
    },
}