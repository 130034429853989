import { useLocation, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { parseParams } from '../../helpers'
import { Product } from '../Product'
import { Categories } from '../Categories'
import { useAppDispatch } from '../../hooks/redux'
import { getId } from '../../store/product/productSlice'

export const Page = () => {
  const { id } = useParams<{ id: string }>()
  const [pageState, setStatePage] = useState('')
  const [idState, setStateId] = useState(0)
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const params = parseParams(id);
    (async () => {
      if (!params) return
      setStatePage(params[0])
      setStateId(+params[1])
    })()
  }, [location])

  useEffect(() => {
    dispatch(getId(idState))
  }, [idState])

  if (pageState === 'category')
    return <Categories id={idState}/>

  if (pageState === 'product')
    return <Product product_id={idState}/>

  return null
}