import { Box, Theme } from '@mui/material'
import { AddCartButton, ArrowMiniButton, DeleteButton } from '../Button'
import addIcon from '../../images/add.svg'
import arrowLeft from '../../images/arrow-mini-left.svg'
import arrowRight from '../../images/arrow-mini-right.svg'
import trashIcon from '../../images/trash.svg'
import styled from 'styled-components'
import { useAppDispatch } from '../../hooks/redux'
import { removeItemCart, updateCountItem } from '../../store/cart/cartSlice'
import { Key } from 'react'
import { t } from 'i18next'
import { useResponsive } from '../../hooks/useResponsive'

const ONE_SIZE = 'onesize'

export const CategoryItem = ({
                               src,
                               name,
                               onClick,
                             }: { key?: Key | null | undefined, src?: string, name: string, onClick?: any, }) => {
  return (
     <Box onClick={onClick} sx={styles.categoryBox}>
       <img draggable={false} src={src} alt={'Category'}/>
       {name}
     </Box>
  )
}

export const ProductItem = (
   {
     src,
     name,
     price,
     onClick,
     addToCard,
   }: { key?: Key | null | undefined, src: string, name: string, price: number, onClick?: any, addToCard?: any },
) => {
  return (
     <Box sx={styles.itemBox} onClick={onClick}>
       <Box sx={styles.imgBoxProduct}>
         <img draggable={false} src={src} alt={'Product'}/>
       </Box>
       <TextBox>
         <Box component={'span'} sx={styles.productName}>{name}</Box>
         <Box component={'span'} sx={styles.productPrice}>$ {price.toLocaleString('en-US', {
           minimumFractionDigits: 2,

         })}</Box>
       </TextBox>
       <AddCartButton onClick={addToCard}>{t('products.buttons.addToCart')} <img src={addIcon}
                                                                                 alt={'Add Icon'}/></AddCartButton>
     </Box>)
}

const TextBox = styled('div')`
  width: 100%;
  height: 110px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;,
`

export const CartItem = (props: any) => {
  const { src, name, price, count, id, size } = props
  const dispatch = useAppDispatch()
  const { isMinor } = useResponsive()
  const isOneSize = size === ONE_SIZE

  const deleteItem = (itemID: number) => {
    dispatch(removeItemCart(itemID))
  }

  const handleAddCount = (itemID: number) => {
    dispatch(updateCountItem('add', itemID))

  }
  const handleRemCount = (itemID: number) => {
    dispatch(updateCountItem('rem', itemID))
  }

  return (
     <Box sx={isMinor ? stylesCart.minorCartBox : stylesCart.cartBox}>
       <Box sx={stylesCart.miniBox}>
         <Box component={'img'} sx={stylesCart.cartImg} src={src}/>
         <Box sx={stylesCart.labelBox}>
           <Box sx={stylesCart.cartLabel} component={'span'}>{name}</Box>
           <Box>
             <Box sx={stylesCart.priceItem} component={'span'}>
               $ {price.toLocaleString('en-US', { minimumFractionDigits: 2 })}
             </Box>
             {!isOneSize && (<Box sx={stylesCart.size} fontSize={'30px'} ml={'15px'}  component={'span'}>{size}</Box>)}
           </Box>
         </Box>
       </Box>
       <Box sx={stylesCart.miniBox}>
         <Box sx={stylesCart.countBox}>
           <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
             <ArrowMiniButton onClick={() => handleRemCount(id)}><Box component={'img'}
                                                                      src={arrowLeft}/></ArrowMiniButton>
             <Box component={'span'} sx={stylesCart.countText}>{count}</Box>
             <ArrowMiniButton onClick={() => handleAddCount(id)}><Box component={'img'}
                                                                      src={arrowRight}/></ArrowMiniButton>
           </Box>
           <Box sx={stylesCart.priceItem2}>
             $ {(price * count).toLocaleString('en-US', { minimumFractionDigits: 2 })}
           </Box>
         </Box>
         <Box>
           <DeleteButton onClick={() => deleteItem(id)}>
             <Box component={'img'} src={trashIcon}/>
           </DeleteButton>
         </Box>
       </Box>
     </Box>
  )
}

const styles = {
  categoryBox: {
    width: '296px',
    height: '256px',
    color: '#ffffff',
    background: '#1C1C1C',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    border: (theme: Theme) => `3px solid ${theme.palette.secondary.main}`,
    borderRadius: '16px',
    fontSize: '28px',
    fontWeight: '700',
    'img': {
      width: '144px',
      height: '144px',
      overflow: 'hidden',
      objectFit: 'cover',
    },
  },

  itemBox: {
    width: '296px',
    height: '404px',
    color: '#ffffff',
    background: '#1C1C1C',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: (theme: Theme) => `3px solid ${theme.palette.secondary.main}`,
    borderRadius: '16px',
    padding: '16px 0 18px',
    'img': {
      width: '160px',
      height: '160px',
      overflow: 'hidden',
      objectFit: 'cover',
    },
  },
  imgBoxProduct: {
    width: '264px',
    background: '#ffffff',
    backgroundClip: 'content-box',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productName: {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    fontSize: '28px',
    whiteSpace: 'wrap',
    maxWidth: '470px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: '600',
  },
  productPrice: {
    display: 'block',
    fontSize: '32px',
    fontWeight: '400',
  },
}

const stylesCart = ({
  cartBox: {
    height: '176px',
    background: '#1C1C1C',
    border: (theme: Theme) => `3px solid ${theme.palette.secondary.main}`,
    borderRadius: '16px',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    padding: '32px',
    justifyContent: 'space-between',
  },
  minorCartBox: {
    height: '136px',
    background: '#1C1C1C',
    border: (theme: Theme) => `3px solid ${theme.palette.secondary.main}`,
    borderRadius: '16px',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    justifyContent: 'space-between',
  },
  cartImg: {
    width: '112px',
    height: '112px',
    overflow: 'hidden',
    objectFit: 'cover',
  },
  labelBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '16px',
    justifyContent: 'space-between',
  },
  cartLabel: {
    color: '#ffffff',
    fontSize: '40px',
    fontWeight: '700',
    whiteSpace: 'nowrap',
    maxWidth: '470px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  priceItem: {
    color: '#E1E1E1',
    opacity: '50%',
    fontSize: '36px',
    fontWeight: '400',
  },
  size: {
    border: '3px solid #E1E1E1',
    borderRadius: '8px',
    padding: '5px 10px',
    fontWeight: '700',
    fontSize: '28px',
    color: '#E1E1E1',
  },
  priceItem2: {
    color: '#ffffff',
    fontSize: '36px',
    fontWeight: '500',
    textAlign: 'right',
  },
  countText: {
    color: '#ffffff',
    fontSize: '32px',
    fontWeight: '600',
  },
  countBox: {
    width: '200px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: '40px',
  },
  miniBox: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },

})