import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from '../index'
import axios, { AxiosError } from 'axios'
import { updateTotal } from '../cart/cartSlice'
import { handleError } from '../error/errorSlice'
import { showNotification } from '../notification/notificationSlice'
import { NOTIFICATION_TYPE } from '../../enum/constant'
import { NavigateFunction } from 'react-router-dom'
import i18n from 'i18next'

type initialStateType = {
  root_items: any[]
  root_pagination: {
    size: number
    page: number
    total: number
  },
  currentPage: number,
  maxPage: number,
  currentProduct: any,
  id: number,
  currentSearchValue: string
  rootLoading: boolean
}

const initialState: initialStateType = {
  root_items: [],
  root_pagination: { size: 0, page: 1, total: 0 },
  currentPage: 1,
  maxPage: 1,
  currentProduct: {},
  id: 0,
  currentSearchValue: '',
  rootLoading: false,
}

const productSlice = createSlice({
  name: 'productSlice',
  initialState,
  reducers: {
    setRootItem: (state, { payload }: { payload: any[] }) => {
      state.root_items = payload
    },
    setPagination: (state, { payload }: { payload: any }) => {
      state.root_pagination = payload
    },
    setMaxPage: (state, { payload }: { payload: number }) => {
      state.maxPage = payload
    },
    nextPage: (state) => {
      state.currentPage += 1
    },
    prevPage: (state) => {
      state.currentPage -= 1
    },
    clearRootItem: (state) => {
      state.root_items = []
    },
    setCurrentProduct: (state, { payload }: { payload: any }) => {
      state.currentProduct = payload
    },
    resetCurrentPage: (state) => {
      state.currentPage = 1
    },
    setId: (state, { payload }: { payload: number }) => {
      state.id = payload
    },
    setCurrentSearchValue: (state, { payload }: { payload: string }) => {
      state.currentSearchValue = payload
    },
    setRootLoading: (state, { payload }: { payload: boolean }) => {
      state.rootLoading = payload
    }
  },
})

const { setRootItem, setPagination, setMaxPage, resetCurrentPage, setId, setRootLoading } = productSlice.actions
export const { clearRootItem, nextPage, prevPage, setCurrentSearchValue, setCurrentProduct } = productSlice.actions
export default productSlice.reducer

export const getRootElement = (page = 1, navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setRootLoading(true))
    dispatch(resetCurrentPage())
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }
    const items = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/category-content/list/`, { headers: headers, params: { page, size: 6 } })
    if (!items?.data?.items) return;
    dispatch(setRootItem(items.data.items))
    dispatch(setPagination({ total: items.data.total, page: items.data.page, size: items.data.size }))
    dispatch(setMaxPage(Math.ceil(items.data.total / 6)))
    dispatch(setRootLoading(false))
  } catch (err: AxiosError | any) {
    dispatch(handleError(err, navigate))
  }

}

export const getElementFromCategory = (category_id: number | null, page: number = 1, navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }
    const params = {
      category_id,
      page,
      size: 6
    }
    const items = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/category-content/list/?category_id=${category_id}&page=${page}&size=6`, { headers, params })
    dispatch(setRootItem(items.data.items))
    dispatch(setPagination({ total: items.data.total, page: items.data.page, size: items.data.size }))
    dispatch(setMaxPage(Math.ceil(items.data.total / items.data.size)))
    dispatch(updateTotal())
  } catch (err: AxiosError | any) {
    dispatch(handleError(err, navigate))
  }
}

export const getProduct = (product_id: number, navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }
    const items = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/product/details/`, { headers, params: { product_id } })

    dispatch(setCurrentProduct(items?.data))
    dispatch(updateTotal())
    return items?.data
  } catch (err: AxiosError | any) {
    dispatch(handleError(err, navigate))
  }
}

export const getSearchProducts = (match_phrase: string, category_id?: number,  page: number = 1 ) => async (dispatch: AppDispatch) => {
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }
    const params = {
      match_phrase,
      category_id,
      page,
      size: 6
    }

    const searchProducts = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/category-content/search/`, { headers, params })
    let res = searchProducts?.data
    dispatch(showNotification("Success", "found"));
    dispatch(setRootItem(res?.items))
    dispatch(setPagination({ total: res.total, page: res.page, size: res.size }))
    dispatch(setMaxPage(Math.ceil(res.total / 6)))
  } catch (e:AxiosError | any) {
    dispatch(setRootItem([]))
    dispatch(setPagination({ total: 0, page: 1, size: 0 }))
    dispatch(setMaxPage(Math.ceil(0 / 6)))
    dispatch(showNotification(`${i18n.t("header.no-results")} ${match_phrase}`, NOTIFICATION_TYPE.NOT_FOUND));
    console.log(e)
  }
}

export const getId = (id: number) => async (dispatch: AppDispatch) => {
  dispatch(setId(id))
}