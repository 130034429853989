import React from 'react'
import { Box, Typography } from '@mui/material'
import QrCodeMob from '../../images/qrCodeMobile.png'
import PaymentFooter from '../../components/PaymentFooter/PaymentFooter'
import { useNavigate } from 'react-router-dom'
import { path } from '../../enum/constant'
import { useAppSelector } from '../../hooks/redux'
import { selectToken } from '../../store/crypto/selectors'
import { t } from 'i18next'

const QrCodeReady = () => {
  const token = useAppSelector(selectToken)

  const navigate = useNavigate()
  const goToScanQr = () => navigate(path.scanQr())

  return (
    <Box sx={styles.container} mt={'72px'}>
      <Box sx={styles.qrBox}>
        <img width={'451px'} height={'900px'} src={QrCodeMob} alt="qrCodeMobile"/>
        <Box>
          <Typography mb={'20px'} color={'white'} fontSize={'36px'} fontWeight={500}>{t("crypto.qrReady.title")}</Typography>
          <Box sx={styles.box}>
            <Typography mb={'20px'} color={'white'} fontSize={'40px'} fontWeight={500}>
              1. {t("crypto.qrReady.subtitleOne")} {token.toUpperCase()} {t("crypto.qrReady.subtitleTwo")}</Typography>
            <Typography color={'white'} fontSize={'40px'} fontWeight={500}>2. {t("crypto.qrReady.subtitle")} {token.toUpperCase()}</Typography>
          </Box>
        </Box>
      </Box>
      <PaymentFooter visible text={`${t("crypto.buttons.ready")}`} onClick={goToScanQr}/>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    height: '100%',
  },
  qrBox: {
    display: 'flex',
    flexDirection: 'space-between',
    alignItems: 'center',
  },
  box: {
    border: '3px solid #667080',
    background: '#1C1C1C',
    borderRadius: '16px',
    padding: '40px',
  },
}
export default QrCodeReady