import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from '../index'
import axios, { AxiosError } from 'axios'
import { handleError } from '../error/errorSlice'
import { NavigateFunction } from 'react-router'

type itemCartType = {
  id: number
  count: number
  price: number
  name: string
  image: any
}

type initialStateType = {
  length: number
  cart: Record<number, itemCartType>
  total: number,
  startSessionClosing: boolean,
  cashLoading: boolean
}

const initialState: initialStateType = {
  length: 0,
  cart: {},
  total: 0,
  startSessionClosing: false,
  cashLoading: false
}

const kioskSlice = createSlice({
  name: 'kioskSlice',
  initialState,
  reducers: {
    setStartSession(state) {
      state.startSessionClosing = true
    },
    setCloseSession(state) {
      state.startSessionClosing = false
    },
    setCashLoading(state, { payload }: { payload: boolean }) {
      state.cashLoading = payload
    },
  },
})

export const { setStartSession, setCloseSession, setCashLoading } = kioskSlice.actions
export default kioskSlice.reducer

export const getSession = (navigate: NavigateFunction, token?: string | null, is_test?: string | null) => async (dispatch: AppDispatch) => {
  try {
    if (!token) {
      dispatch(handleError(null, navigate))
      return
    }

    localStorage.setItem('kg_session', token)

    const tempData = {
      kg_session: token,
      is_test: is_test ?? false,
    }

    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/session/init/`, tempData)
    if (response?.data?.session_token) {
      localStorage.setItem('session_token', response.data.session_token)
      localStorage.setItem('organization_name', response.data.organization_name)
      localStorage.setItem('kiosk_id', response.data.kiosk_id)
      localStorage.setItem('kiosk_name', response.data.kiosk_name)
      localStorage.setItem('kiosk_timezone', response.data.kiosk_timezone)
      localStorage.setItem('kiosk_address', response.data.kiosk_address)
      localStorage.setItem('kiosk_model', response.data.kiosk_model)
      localStorage.setItem('contact_email', response.data.organization_settings.contact_email)
      localStorage.setItem('contact_phone_number', response.data.organization_settings.contact_phone_number)
      localStorage.setItem('enable_card_payment', response.data.organization_settings.enable_card_payment)
      localStorage.setItem('enable_cash_payment', response.data.organization_settings.enable_cash_payment)
      localStorage.setItem('enable_crypto_payment', response.data.organization_settings.enable_crypto_payment)
      localStorage.setItem('enable_counter_payment', response.data.organization_settings.enable_counter_payment)
      dispatch(setStartSession())
    }
  } catch (err: AxiosError | any) {
    dispatch(handleError(err, navigate))
  }

}

export const closeSession = (navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/session/close/`, {}, { headers })
    console.log(response)
    localStorage.clear()
    dispatch(setCloseSession())
  } catch (err: AxiosError | any) {
    dispatch(handleError(err, navigate))
  }
}

export const cashEnable = (navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
  dispatch(setCashLoading(true))
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }
    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/kiosk/cash/enable/`, {}, { headers })
    dispatch(setCashLoading(false))
    if (res) return true
  } catch (err: AxiosError | any) {
    dispatch(handleError(err, navigate))
    console.log(err)
    return false
  }
}

export const cashDisable = () => async (dispatch: AppDispatch) => {
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }
    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/kiosk/cash/disable/`, {}, { headers })
    console.log(res)
  } catch (err: AxiosError | any) {
    console.log(err)
  }
}

export const getExecute = () => async () => {
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }
    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/order/execute/`, {}, { headers })
    console.log(res)
  } catch (err: AxiosError | any) {
    console.log(err)
  }
}

export const setPaymentMethod = (method: string, navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
  try {
    const session_token = localStorage.getItem('session_token')
    const headers: any = {
      'x-session-id': session_token,
    }
    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/order/set-payment-method/`, { payment_type: method }, { headers })
    console.log(res)
  } catch (err: AxiosError | any) {
    dispatch(handleError(err, navigate))
    console.log(err)
  }
}
