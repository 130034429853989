import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import PhoneInputComponent from '../../components/Shared/PhoneInput/PhoneInput'
import KeyBoardPhone from '../../components/Keyboard/KeyBoardPhone'
import PaymentFooter from '../../components/PaymentFooter/PaymentFooter'
import { parseIncompletePhoneNumber } from 'libphonenumber-js/core'
import {useNavigate, useParams} from 'react-router-dom'
import { path } from '../../enum/constant'
import CodeInputComponent from '../../components/Shared/CodeInput/CodeInput'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import {checkVerificationCode, sendVerificationCode, setPhoneNumber} from '../../store/crypto/cryptoSlice'
import { selectPhoneNum } from '../../store/crypto/selectors'
import { t } from 'i18next'
import {setInfoActionButton} from "../../store/cart/cartSlice";

const PhoneCodePage = ({ code, withRedirect = false }: { code: boolean, withRedirect?: boolean }) => {
  const [valuePhone, setPhone] = useState<any>('+1')
  const [valueCode, setCode] = useState<any>('')
  const [errorMsg, setErrorMsg] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState(false)
  const [isValidPhone, setIsValidPhone] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const phoneNumber = useAppSelector(selectPhoneNum)

  const goBack = () => navigate(-1)

  const setFormatValue = (v: string) => {
    if (code) {
      if (!v) return setCode('')
      setCode(v)
      return
    }
    if (!v) return setPhone('+')
    if (v && !v.includes('+')) v = '+' + v
    setPhone(parseIncompletePhoneNumber(v))
  }

  useEffect(() => {
    if (code && valueCode.length === 5) {
      setVisible(true)
      return
    }
    if (code && !valuePhone.length) {
      setErrorMsg(false)
      setIsValidPhone(false)
      setVisible(false)
      return
    }
    if (!code && (valuePhone.length === 12 || valuePhone.length === 13)) {
      setVisible(true)
      setIsValidPhone(false)
      return
    }
    setVisible(false)
    setErrorMsg(false)
    setIsValidPhone(false)

  }, [valuePhone, valueCode])

  const getAuthCode = async () => {
    if (!withRedirect) {
      if (code) {
        try {
          setLoading(true)
          const res = await dispatch(checkVerificationCode(phoneNumber, valueCode))
          setErrorMsg(!res)
          if (res) {
            navigate(path.coins())
          }
        } finally {
          setLoading(false)
        }
      }

      if (!code) {
        try {
          setLoading(true)
          const res = await dispatch(sendVerificationCode(valuePhone))
          setIsValidPhone(!res)
          if (res) {
            setPhone('')
            navigate(path.codePage())
          }
        } finally {
          setLoading(false)
        }
      }
    } else {
      dispatch(setPhoneNumber(valuePhone))
      dispatch(setInfoActionButton(true));
      goBack()
    }

  }

  const resendVerificationCode = async () => {
    setCode('')
    await dispatch(sendVerificationCode(phoneNumber))
  }

  const renderPhoneOrAuth = () => {
    if (code) {
      return (
         <Box>
           <Typography
              textAlign={'center'}
              color={'rgba(225, 225, 225, 0.5)'}
              fontSize={'48px'}
              lineHeight={'56px'}
              mb={'25px'}
           >
             {t("crypto.codeTitle")}
           </Typography>
           <CodeInputComponent errorMsg={errorMsg} setValue={setCode} value={valueCode}/>
           {errorMsg &&
           <Typography
               textAlign={'center'}
               color={'red'}
               fontSize={'30px'}
               position={'absolute'}
               left={'50%'}
               width={"100%"}
               sx={{transform: "translate(-50%)"}}
           >
             {t("crypto.errorCode")}
           </Typography>
           }
           <Typography
              fontSize={'36px'}
              m={'48px auto 48px auto'}
              maxWidth={'760px'}
              color={'white'}
              textAlign={'center'}
              lineHeight={'40px'}
           >
             {t("crypto.codeSubtitle")}
           </Typography>
         </Box>
      )
    }
    return (
       <Box>
         <Typography
            textAlign={'center'}
            color={'rgba(225, 225, 225, 0.5)'}
            fontSize={'48px'}
            lineHeight={'56px'}
            mb={'25px'}
         >
           {t("crypto.phoneTitle")}
         </Typography>
         <PhoneInputComponent setValue={setPhone} value={valuePhone} isValidPhone={isValidPhone}/>
         {isValidPhone &&
         <Typography
             textAlign={'center'}
             color={'red'}
             fontSize={'30px'}
             position={'absolute'}
             left={'50%'}
             width={"100%"}
             sx={{transform: "translate(-50%)"}}
         >
           {t("crypto.errorPhone")}
         </Typography>
         }
         <Typography
            fontSize={'36px'}
            m={'48px auto 48px auto'}
            maxWidth={'760px'}
            color={'white'}
            textAlign={'center'}
            lineHeight={'40px'}
         >
           {withRedirect ? t('crypto.smsPhoneSubtitle') : t("crypto.phoneSubtitle")}
         </Typography>
       </Box>
    )
  }

  return (
     <Box sx={styles.container} mt={'88px'}>
       <Box>
         {renderPhoneOrAuth()}
         <KeyBoardPhone length={code} value={code ? valueCode : valuePhone} setValue={setFormatValue}/>
       </Box>
       <PaymentFooter
          loading={loading}
          visible={visible}
          resend={resendVerificationCode}
          isResent={code}
          text={`${t("crypto.buttons.next")}`}
          disable={errorMsg || isValidPhone}
          clear={setCode}
          clearPhone={() => setPhone('+')}
          onClick={getAuthCode}/>
     </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    height: '100%',
  },
}

export default PhoneCodePage