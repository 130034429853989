import { Box, Theme } from '@mui/material'
import { BackButton, CartButton, ExitButton } from '../Button'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import ArrowLeft from '../../images/arrow-left.svg'
import { Search } from '../Input'
import CartIcon from '../../images/cart.svg'
import Breadcrumbs from '../Breadcrumbs'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { path } from '../../enum/constant'
import { useEffect, useState } from 'react'
import { closeSession } from '../../store/kiosk/kioskSlice'
import { parseParams, removeHeader } from '../../helpers'
import { setCurrentSearchValue } from '../../store/product/productSlice'
import { hideNotification } from '../../store/notification/notificationSlice'
import { useTranslation } from 'react-i18next'

export const Header = () => {
  const cartLength = useAppSelector(state => state.cart.length)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { id } = useParams<{ id: string }>()
  const [isDisable, setIsDisable] = useState(false)
  const isPathHome = pathname === path.home()
  const isPathCart = pathname === path.cart()
  const isRightPath = removeHeader(pathname)
  const { t } = useTranslation();

  const handleClick = () => {
    navigate(path.cart())
  }

  const goBack = () => {
    dispatch(setCurrentSearchValue(''))
    dispatch(hideNotification())
    navigate(-1)
  }

  useEffect(() => {
    if (isPathHome) return setIsDisable(!isDisable)
    setIsDisable(false)
  }, [pathname])


  const renderSearch = () => {
    const params = parseParams(id)
    const isProduct = params && params[0] === 'product'
    if (isPathCart) return null
    if (isProduct) return null
    return <Box width={'636px'}><Search/></Box>
  }

  const handleExit = () => {
    dispatch(closeSession(navigate))
  }

  const renderBackBtn = () => {
    if (!isDisable) return (
       <Box>
         <BackButton onClick={goBack}>
           <img src={ArrowLeft} alt={'Back'}/>
           {t('header.buttons.back')}
         </BackButton>
       </Box>
    )
  }

  const renderHeaderItems = () => {
    if (isRightPath) return null
    return (
       <Box
          style={!isDisable ?
             { justifyContent: 'space-between' } :
             { justifyContent: 'flex-end' }}
          sx={styles.navigationBox}
       >
         {renderBackBtn()}
         {renderSearch()}
         <Box>
           <CartButton onClick={() => handleClick()} badgeContent={cartLength} color={'primary'}>
             <img src={CartIcon} alt={'cart'}/>
           </CartButton>
         </Box>
       </Box>
    )
  }

  return (
     <Box>
       <Box sx={styles.headerBox}>
         <Breadcrumbs/>
         <Box><ExitButton onClick={handleExit} children={t('header.buttons.exit')}/></Box>
       </Box>
       {renderHeaderItems()}
     </Box>
  )
}


const styles = {
  breadcrumbBox: {
    border: (theme: Theme) => `3px solid ${theme.palette.secondary.main}`,
    borderRadius: '16px',
    height: '64px',
    minWidth: '64px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '15px',
    '&>span': {
      color: '#E1E1E1',
      fontSize: '28px',
      fontWeight: '500',
      opacity: '50%',
    },
    '&>span:last-child': {
      color: '#ffffff',
      fontSize: '28px',
      fontWeight: '500',
      opacity: '100',
      paddingRight: '16px',
    },
  },
  slash: {
    color: (theme: Theme) => theme.palette.secondary.main,
    fontSize: '28px',
    fontWeight: '500',
    opacity: '50%',
    paddingX: '16px',
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '25px',
  },
  navigationBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '32px',
  },
}