import { Badge, Button } from '@mui/material'
import styled from 'styled-components'
import { ButtonBaseProps } from '../ButtonBase'
import { ComponentType } from 'react'
import arrowRight from '../../images/arrow-mini-right.svg'
import arrowLeft from '../../images/arrow-mini-left.svg'
import { makeStyles } from '../../hooks'

type CustomButtonProps = ButtonBaseProps & {
  fontSize?: string,
  colorText?: string,
  background?: string,
  width?: string,
  fontWeight?: number
  border?: string
}

export const ExitButton = styled(Button)<CustomButtonProps>`
  font-size: ${props => props.fontSize ? `${props.fontSize}!important` : '36px!important'};
  text-align: center !important;
  color: ${props => props.colorText ? `${props.colorText}!important` : '#ffffff!important'};
  background: ${props => props.background ? `${props.background}!important` : '#667080!important'};
  width: ${props => props.width ? `${props.width}!important` : '126px!important'};
  height: 80px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  border-radius: 16px !important;
`

export const UniversalButton = styled(Button)<CustomButtonProps>`
  font-size: ${props => props.fontSize ? `${props.fontSize}!important` : '36px!important'};
  text-align: center !important;
  color: ${props => props.colorText ? `${props.colorText}!important` : '#ffffff!important'};
  background: ${props => props.background ? `${props.background}!important` : '#667080!important'};
  width: ${props => props.width ? `${props.width}!important` : '126px!important'};
  height: 80px !important;
  font-weight: ${props => props.fontWeight ? `${props.fontWeight}!important` : '500!important'};
  text-transform: none !important;
  border-radius: 16px !important;
  border: ${props => props.border ? `${props.border}!important` : 'unset'};
  &:disabled {
    color: rgba(102, 112, 128, 0.5)!important;
    border: 3px solid rgba(102, 112, 128, 0.5)!important;
  }
`

export const BackButton = styled(Button)`
  font-size: 28px !important;
  text-align: center !important;
  color: #667080 !important;
  border: 3px #667080 solid !important;
  background: transparent !important;
  width: 180px !important;
  height: 80px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  border-radius: 16px !important;
  gap: 10px !important;
`

export const CartButton = styled(Badge)`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  //text-align: center!important;
  //color: #667080!important;
  border: 3px #667080 solid !important;
  background: transparent !important;
  width: 80px !important;
  height: 80px !important;
  border-radius: 16px !important;

  img {
    width: 40px !important;
    height: 40px !important;
  }

  span {
    width: 40px !important;
    height: 40px !important;
    font-size: 32px !important;
    border-radius: 50% !important;
    top: 5px !important;
    right: 5px !important;
    font-weight: 600 !important;
  }
`

export const ArrowButton = styled(Button)`
  font-size: 36px !important;
  text-align: center !important;
  color: #667080 !important;
  border: 3px #667080 solid !important;
  background: transparent !important;
  width: 126px !important;
  height: 80px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  border-radius: 16px !important;
`

export const ArrowMiniButton = styled(Button)`
  display: inline-flex !important;
  font-size: 36px !important;
  text-align: center !important;
  color: #667080 !important;
  border: 3px #667080 solid !important;
  background: transparent !important;
  width: 48px !important;
  height: 48px !important;
  text-transform: none !important;
  border-radius: 8px !important;
  min-width: unset !important;
`

export const AddCartButton = styled(Button)`
  width: 264px !important;
  height: 64px !important;
  background: #07F285 !important;
  font-size: 28px !important;
  text-align: center !important;
  color: #1C1C1C !important;
  //border: 3px #667080 solid!important;
  font-weight: 700 !important;
  text-transform: none !important;
  border-radius: 8px !important;
  gap: 8px !important;

  img {
    width: 32px !important;
    height: 32px !important;
  }
`

export const DeleteButton = styled(Button)`
  font-size: 36px !important;
  text-align: center !important;
  color: #ffffff !important;
  //border: 3px #667080 solid!important;
  background: #F44545 !important;
  width: 48px !important;
  height: 48px !important;
  text-transform: none !important;
  border-radius: 8px !important;
  min-width: unset !important;
`

export const ConfirmButton = styled(Button)`
  font-size: 28px !important;
  font-weight: 700 !important;
  text-align: center !important;
  color: #1C1C1C !important;
  background: #07F285 !important;
  width: 214px !important;
  height: 80px !important;
  text-transform: none !important;
  border-radius: 16px !important;
`

export const BuyButton = styled(Button)`
  font-size: 28px !important;
  font-weight: 700 !important;
  text-align: center !important;
  color: #1C1C1C !important;
  background: #07F285 !important;
  width: 200px !important;
  height: 80px !important;
  text-transform: none !important;
  border-radius: 16px !important;
  &:disabled {
    color: rgba(102, 112, 128, 0.5)!important;
    border: 3px solid rgba(102, 112, 128, 0.5)!important;
    background: transparent!important;
  }
`

export const AddCartProductButton = styled(Button)`
  width: 246px !important;
  height: 80px !important;
  background: transparent !important;
  font-size: 28px !important;
  text-align: center !important;
  color: #07F285 !important;
  border: 3px #07F285 solid !important;
  font-weight: 700 !important;
  text-transform: none !important;
  border-radius: 16px !important;
  gap: 8px !important;
  position: relative!important;
  img {
    width: 32px !important;
    height: 32px !important;
  }
  &:disabled {
    color: rgba(102, 112, 128, 0.5)!important;
    border: 3px solid rgba(102, 112, 128, 0.5)!important;
    background: transparent!important;
    img {
      display: none;
    }
  }
`

export const ArrowMiddleButton = styled(Button)`
  display: inline-flex !important;
  font-size: 36px !important;
  text-align: center !important;
  color: #667080 !important;
  border: 3px #667080 solid !important;
  background: transparent !important;
  width: 72px !important;
  height: 72px !important;
  text-transform: none !important;
  border-radius: 8px !important;
  min-width: unset !important;
` as ComponentType<ButtonBaseProps>


export const SliderButton = ({ type, onClick }: { type: string, onClick?: any }) => {
  if (type === 'prev') return (
     <ArrowMiniButton sx={{ ...styles.arrow, ...styles.prev }} onClick={onClick}>
       <img src={arrowLeft} alt={'Arrow Left'}/>
     </ArrowMiniButton>
  )
  if (type === 'next') return (
     <ArrowMiniButton sx={{ ...styles.arrow, ...styles.next }} onClick={onClick}>
       <img src={arrowRight} alt={'Arrow Right'}/>
     </ArrowMiniButton>
  )
  return <ArrowMiniButton/>
}

const styles = makeStyles({
  arrow: {
    position: 'absolute',
    zIndex: 1,
    bottom: '-100px',
  },
  next: {
    right: 0,
  },
  prev: {
    left: 0,
  },
})
