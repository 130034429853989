import { CategoryItem, ProductItem } from '../../components/Box'
import { Box } from '@mui/material'
import {
  clearRootItem,
  getId,
  getProduct,
  getRootElement,
  setCurrentSearchValue,
} from '../../store/product/productSlice'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { setNavigateNext } from '../../store/navigation/navigationSlice'
import { NOTIFICATION_TYPE, path, query } from '../../enum/constant'
import { addItemCart } from '../../store/cart/cartSlice'
import { getImage } from '../../helpers'
import { Pagination } from '../../components/Pagination'
import SearchFail from '../../components/SearchFail/SearchFail'
import { selectNotification } from '../../store/notification/selectors'
import Loader from '../../components/Shared/Loader/Loader'
import { Drawer } from '../../components/Drawer'
import { useResponsive } from '../../hooks/useResponsive'

const ONE_SIZE = 'onesize'


export const Home = () => {
  const dispatch = useAppDispatch()
  const root_items = useAppSelector(state => state.product.root_items)
  const loading = useAppSelector(state => state.product.rootLoading)
  const { notificationType } = useAppSelector(selectNotification)
  const [data, setData] = useState<any[]>([])
  const [openDrawer, setOpenDrawer] = useState(false)
  const [size, setSize] = useState('')
  const [product, setProduct] = useState<any>({})
  const [load, setLoad] = useState<boolean>(false)
  const isSearchFail = notificationType === NOTIFICATION_TYPE.NOT_FOUND

  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()
  const location = useLocation()
  const { isMinor } = useResponsive()


  useEffect(() => {
    const session_token = localStorage.getItem('session_token')
    if (session_token)
      dispatch(getRootElement(currentPage, navigate))
  }, [currentPage])

  useEffect(() => {
    if (root_items.length) {
      const copy = [...root_items]
      const temp: any = copy.sort((a, b) => {
        if (a.entity_type < b.entity_type) return -1
        if (a.entity_type > b.entity_type) return 1
        return 0
      })
      setData(temp)
    }
  }, [root_items])

  useEffect(() => {
    dispatch(getId(0))
  }, [])

  const handleOpenCategory = (id: string, name: string) => {
    const payload = {
      dataNavigate: { navigate, location },
      to: path.next(`category_${id}`),
      search: query.category(id, name),
      name: name,
    }
    dispatch(setCurrentSearchValue(''))
    dispatch(setNavigateNext(payload))
    dispatch(clearRootItem())
  }

  const handleOpenProduct = (id: string, name: string) => {
    const payload = {
      dataNavigate: { navigate, location },
      to: path.next(`product_${id}`),
      search: query.product(id, name),
      name: name,
    }
    dispatch(setCurrentSearchValue(''))
    dispatch(setNavigateNext(payload))
  }

  const getSize = () => {
    if (!product.variations) return
    if (size === '') return
    const sizeKeys = Object.keys(product.variations[0])
    const formatted = product.variations[0][sizeKeys[0]]
    let obj = null
    if (formatted) {
      obj = formatted.find((el: any) => el.value === size)
    }
    const newProduct = {
      ...product,
      id: obj?.product_item_id,
      size: obj?.value,
    }
    dispatch(addItemCart({ ...newProduct, count: 1 }))
    setSize('')
  }

  useEffect(() => {
    getSize()
  }, [size])


  const handleClick = async (e: any, value: any) => {
    e.stopPropagation()
    setLoad(true)
    try {
      const res = await dispatch(getProduct(value.id, navigate))
      setProduct(res)
      const sizeKeys = Object.keys(res.variations[0])
      const oneSize = res.variations[0][sizeKeys[0]].filter((el: any) => el.value !== ONE_SIZE)
      if (!oneSize?.length) {
        setSize(ONE_SIZE)
        return
      }
      setOpenDrawer(true)
    } finally {
      setLoad(false)
    }
  }

  const getThingSize = (size: string) => {
    if (size) return setSize(size)
  }


  const renderItems = () => {
    if (isSearchFail) return <SearchFail />
    return (
      <Box sx={isMinor ? styles.minorContainer : { display: 'flex', flexWrap: 'wrap', gap: '32px', marginTop: '65px' }}>
        {data?.map((value) => {
          if (value.entity_type === 'product') return (
            <ProductItem
              key={`product_${value.id}`}
              onClick={() => handleOpenProduct(value.id, value.name)}
              addToCard={(e: any) => handleClick(e, value)}
              src={getImage(value.image.url)}
              name={value.name}
              price={value.price}
            />
          )
          if (value.entity_type === 'category') return (
            <CategoryItem
              key={`category_${value.id}`}
              onClick={() => handleOpenCategory(value.id, value.name)}
              src={getImage(value.image.url)}
              name={value.name}/>
          )
        })}
      </Box>
    )
  }

  if(loading) return <Loader />

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box>
        {renderItems()}
      </Box>
      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <Drawer
         variations={product?.variations}
         size={size}
         getSize={getThingSize}
         open={openDrawer}
         onClose={() => setOpenDrawer(false)}
      />
    </Box>

  )
}

const styles = {
  minorContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    justifyItems: 'center',
    gap: '32px',
    marginTop: '65px',
  }
}