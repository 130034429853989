import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Home } from './pages/Home'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import { Page } from './pages/Page'
import { Layout } from './components/Layout'
import { path } from './enum/constant'
import { Cart } from './pages/Cart'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from './hooks/redux'
import { getSession, setStartSession } from './store/kiosk/kioskSlice'
import { Loading } from './components/Loading'
import { Info } from './pages/Info'
import { Error } from './pages/Error'
import Cash from './pages/Cash'
import Payment from './pages/Payment'
import Coins from './pages/Coins'
import PhoneCodePage from './pages/PhoneCodePage'
import checkCircle from './images/check-circle.svg'
import errorCircle from './images/error-circle.svg'
import InfoCircle from './images/InfoCircle.svg'
import clock from './images/clock.svg'
import QrCodeReady from './pages/QrCodeReady'
import ScanQr from './pages/ScanQr'
import { Patience } from './pages/Patience'
import {CounterConfirm} from "./pages/Counter";

const newTheme = createTheme({
  palette: {
    primary: {
      main: '#07F285',
      light: '#E9EEF7',
    },
    secondary: {
      main: '#667080',
      light: '#667080',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#979AB3',
      secondary: '#4E5386',
    },
  },
})

export const App = () => {
  const dispatch = useAppDispatch()
  const startSessionClosing = useAppSelector(state => state.kiosk.startSessionClosing)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token')
  const is_test = searchParams.get('is_test')

  useEffect(() => {
    localStorage.clear()
    if (!localStorage.getItem('session_token')) {
      dispatch(getSession(navigate, token, is_test ))
    } else {
      dispatch(setStartSession())
    }
  }, [])

  const renderContent = () => {
    if (startSessionClosing) {
      return (
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Home/>}/>
            <Route path={path.cart()} element={<Cart/>}/>
            <Route path={path.next()} element={<Page/>}/>
            <Route path={path.next() + path.next()} element={<Page/>}/>
            <Route path={path.next() + path.next() + path.next()} element={<Page/>}/>
            <Route path={path.next() + path.next() + path.next() + path.next()} element={<Page/>}/>
            <Route path={path.cash()} element={<Cash/>}/>
            <Route path={path.payment()} element={<Payment/>}/>
            <Route path={path.phonePage()} element={<PhoneCodePage code={false}/>}/>
            <Route path={path.codePage()} element={<PhoneCodePage code={true}/>}/>
            <Route path={path.smsPhonePage()} element={<PhoneCodePage code={false} withRedirect={true}/>}/>
            <Route path={path.coins()} element={<Coins/>}/>
            <Route path={path.counter()} element={<CounterConfirm />}/>
            <Route path={path.qrCodeReady()} element={<QrCodeReady/>}/>
            <Route path={path.scanQr()} element={<ScanQr/>}/>
            <Route path={path.cryptoPatience()} element={<Patience/>}/>
            <Route path={path.cryptoInfo()} element={<Info icon={clock} type={'cryptoInfo'}/>}/>
            <Route path={path.cryptoCoins()} element={<Info icon={errorCircle} type={'cryptoCoins'}/>}/>

          </Route>
          <Route path={path.thank()} element={<Info icon={checkCircle} type={'thank'}/>}/>
          <Route path={path.cryptoWhoops()} element={<Info icon={errorCircle} type={'cryptoWhoops'}/>}/>
          <Route path={path.cryptoThank()} element={<Info icon={checkCircle} type={'cryptoThank'}/>}/>
          <Route path={path.printCryptoReceipt()} element={<Info icon={InfoCircle} type={'printCryptoReceipt'}/>}/>
          <Route path={path.printCounterReceipt()} element={<Info icon={checkCircle} type={'printCounterReceipt'} />}/>
          <Route path={path.error()} element={<Error/>}/>
        </Routes>
      )
    }
    return <Loading/>
  }

  return (
    <ThemeProvider theme={newTheme}>
      {renderContent()}
    </ThemeProvider>
  )
}
