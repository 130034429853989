import React from 'react'
import { BackButton, UniversalButton } from '../Button'
import ArrowLeft from '../../images/arrow-left.svg'
import { Box, CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

type PaymentFooterProps = {
  text?: string;
  visible?: boolean;
  onClick?: any;
  resend?: () => void;
  isResent?: boolean
  cancel?: () => void;
  disable?: boolean;
  clear?: any;
  clearPhone?: any;
  loading?: boolean;
}

const PaymentFooter = ({ text, visible, onClick, resend, isResent, cancel, disable, clear, clearPhone, loading }: PaymentFooterProps) => {
  const navigate = useNavigate()

  const goBack = () => {
    if (clear) {
      clear('')
    }
    if (clearPhone) {
      clearPhone()
    }
    navigate(-1)
  }

  const renderResend = () => {
    if (isResent) {
      return (
         <UniversalButton
            children={t("crypto.buttons.resend")}
            colorText={'#FFFFFF'}
            fontSize={'28px'}
            background={'#667080'}
            fontWeight={700}
            width={'207px'}
            onClick={resend}
         />
      )
    }
  }

  return (
     <Box sx={styles.buttonBox}>
       {cancel ? <BackButton onClick={cancel}>
         {t("crypto.buttons.cancel")}
       </BackButton> : <BackButton onClick={goBack}>
         <img src={ArrowLeft} alt={'Back'}/>
         {t("crypto.buttons.back")}
       </BackButton>}
       {renderResend()}
       {visible ? <UniversalButton
          children={loading ? <CircularProgress style={{color: 'black'}}/>: text}
          colorText={'#1C1C1C'}
          fontSize={'28px'}
          background={'#07F285'}
          fontWeight={700}
          width={'180px'}
          onClick={onClick}
          disabled={disable}
       /> : <Box width={'180px'}/>}

     </Box>
  )
}

const styles = {
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}

export default PaymentFooter