/**
 * created 04.08.2023
 */
import React, {useCallback, useEffect, useMemo} from 'react';
import {Box, Typography} from '@mui/material'
import {makeStyles} from '../../hooks'
import {t} from "i18next";
import {useLocation, useMatch, useMatches, useNavigate, useParams} from "react-router-dom";
import PaymentFooter from "../../components/PaymentFooter/PaymentFooter";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {path} from "../../enum/constant";
import {getExecute} from "../../store/kiosk/kioskSlice";
import {printReceipt} from "../../store/cart/cartSlice";
import Loader from "../../components/Shared/Loader/Loader";
import {usePrevious} from "../../hooks/usePrevious";

interface CounterConfirmComponentInterface {
}

function CounterConfirmComponent(props: CounterConfirmComponentInterface) {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const loading = useAppSelector(state => state.cart.cartLoading)
    const error = useAppSelector(state => state.cart.error)
    const prevLoading = usePrevious(loading)


    useEffect(() => {
        // dispatch(printReceipt(navigate))
        navigate(path.printCounterReceipt())
    }, []);

    useEffect(() => {
        if (prevLoading && !loading && !error) navigate(path.printCounterReceipt())
    }, [loading, error]);


    return (
        <Loader />
    );

}

export const CounterConfirm = CounterConfirmComponent;
