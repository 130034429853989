import React, { BaseSyntheticEvent } from 'react'
import { Box, Button, Typography } from '@mui/material'
import MoneyIcon from '../../images/money-icon.svg'
import CardIcon from '../../images/debitCard-icon.svg'
import CryptoIcon from '../../images/crypto-icon.svg'
import CounterIcon from '../../images/money-payment-6400.svg'
import PaymentFooter from '../../components/PaymentFooter/PaymentFooter'
import { useAppDispatch } from '../../hooks/redux'
import { confirmOrder, setOrderId } from '../../store/cart/cartSlice'
import { useNavigate } from 'react-router-dom'
import { path, PAYMENT_METHOD } from '../../enum/constant'
import { setPaymentMethod } from '../../store/kiosk/kioskSlice'
import { t } from 'i18next'


const Payment = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const choosePaymentMethod = async (e: BaseSyntheticEvent) => {
    const payment = e.target.id
    const orderId = await dispatch(confirmOrder(navigate))
    if (!!orderId) {
      dispatch(setOrderId(orderId))
      await dispatch(setPaymentMethod(payment, navigate))
      if (PAYMENT_METHOD.CASH === payment) {
        navigate(path.cash())
      } else if (PAYMENT_METHOD.CRYPTO === payment) {
        navigate(path.phonePage())
      } else if (PAYMENT_METHOD.COUNTER === payment) {
        // navigate(path.counter())
        navigate(path.printCounterReceipt())
      }
    }
  }

  const disableCash = !(JSON.parse(localStorage.getItem('enable_cash_payment') || 'false'))
  const disableCard = !(JSON.parse(localStorage.getItem('enable_card_payment') || 'false'))
  const disableCrypto = !(JSON.parse(localStorage.getItem('enable_crypto_payment') || 'false'))
  const disableCounter = !(JSON.parse(localStorage.getItem('enable_counter_payment') || 'false'))

  return (
     <Box sx={styles.container} mt={'88px'}>
       <Box>
         <Typography
            textAlign={'center'}
            color={'white'}
            fontSize={'64px'}
            fontWeight={700}
            lineHeight={'80px'}
            marginBottom={'88px'}
         >
           {t('crypto.paymentHeader')}
         </Typography>
         <Box>
           {!disableCash && (<Button
              sx={styles.payment}
              onClick={choosePaymentMethod}
              fullWidth
              id={PAYMENT_METHOD.CASH}
              startIcon={<img src={MoneyIcon} alt="money"/>}
           >
             {t('crypto.paymentMethod.cash')}
           </Button>)}
           {!disableCard && (<Button
              sx={styles.payment}
              fullWidth
              id={PAYMENT_METHOD.CARD}
              startIcon={<img src={CardIcon} alt="card"/>}
           >
             {t('crypto.paymentMethod.card')}
           </Button>)}
           {!disableCrypto && (<Button
              sx={styles.payment}
              onClick={choosePaymentMethod}
              fullWidth
              id={PAYMENT_METHOD.CRYPTO}
              startIcon={<img src={CryptoIcon} alt="Cryptocurrency"/>}
           >
             {t('crypto.paymentMethod.crypto')}
           </Button>)}
           {!disableCounter && (<Button
               sx={styles.payment}
               onClick={choosePaymentMethod}
               fullWidth
               id={PAYMENT_METHOD.COUNTER}
               startIcon={<img src={CounterIcon} alt="Counter" style={{width: 80}}/>}
           >
             {t('crypto.paymentMethod.counter')}
           </Button>)}
         </Box>
       </Box>
       <PaymentFooter/>
     </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    height: '100%',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  payment: {
    display: 'flex',
    textTransform: 'none',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '24px',
    borderRadius: '16px',
    border: '3px solid #667080',
    padding: '29px 40px',
    background: '#1C1C1C',
    maxWidth: '760px',
    margin: '0 auto',
    marginBottom: '24px',
    cursor: 'pointer',
    fontSize: '40px',
    color: 'white',
    fontWeight: 700,
    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.2)',
      background: 'rgba(255, 255, 255, 0.1);',
    },
  },
}
export default Payment