import Slider, { Settings } from 'react-slick'
import { Box } from '@mui/material'
import { useAppSelector } from '../../hooks/redux'
import { SliderButton } from '../Button'
import './style.css'
import { Dots } from '../Dots'

export const ProductSlider = () => {

  const images: any[] = useAppSelector(state => state.product.currentProduct.images)
  const image: any = useAppSelector(state => state.product.currentProduct.image)

  const settings: Settings = {
    dots: true,
    customPaging: i => (
      <Dots/>
    ),
    nextArrow: <SliderButton type={'next'}/>,
    prevArrow: <SliderButton type={'prev'}/>,
  }

  const renderImages = () => {
    if (!images?.length && image) return <ImageBox src={image?.url}/>
    return images?.map((value, index, array) => {
      return <ImageBox key={index} src={value.image.url}/>
    })
  }

  return (
    <Slider {...settings}>
      {renderImages()}
    </Slider>
  )
}

const ImageBox = ({ src }: { src: string }) => {
  return (
    <Box>
      <img src={process.env.REACT_APP_SERVER_URL + src} width={'100%'}/>
    </Box>
  )
}
