import { Box } from '@mui/material'
import CloseIcon from '../../../images/close-icon.svg'
import style from './CodeInput.module.css'

type PhoneInputProps = {
  value: any;
  setValue: any;
  errorMsg?: boolean
}

const CodeInputComponent = ({ value, setValue, errorMsg }: PhoneInputProps) => {
  const clearInput = () => setValue('')
  return (
     <Box sx={styles.searchBox} style={errorMsg ? {borderColor: "red"} : {borderColor: ''}}>
       <input
          className={style.phoneInput}
          value={value}
          onChange={setValue}
       />
       <img src={CloseIcon} onClick={clearInput} alt={'Close'}/>
     </Box>
  )
}

const styles = {
  searchBox: {
    width: '100%',
    maxWidth: '760px',
    height: '104px',
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    outline: 'none',
    border: '3px #667080 solid',
    borderRadius: '24px',
    fontSize: '32px',
    margin: '0 auto',
    color: '#ffffff',
    '& > img:nth-of-type(1)': {
      marginRight: '45px',
      cursor: 'pointer',
    },
  },
}
export default CodeInputComponent

