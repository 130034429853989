import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { selectNotification } from '../../store/notification/selectors'
import { Box, Typography } from '@mui/material'
import { hideNotification } from '../../store/notification/notificationSlice'

const styleForNotification = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}

const SearchFail = () => {
  const dispatch = useAppDispatch()
  const notification = useAppSelector(selectNotification)

  useEffect(() => {
    if (notification.notificationType === 'found') {
      dispatch(hideNotification())
    }
  }, [notification])

  return (
     <Box sx={styleForNotification}>
       <Typography variant={'h2'}>{notification.message}</Typography>
     </Box>
  )
}

export default SearchFail