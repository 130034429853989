import React from 'react'
import Slider, { Settings } from 'react-slick'
import { Dots } from '../Dots'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { path } from '../../enum/constant'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { selectCoins } from '../../store/crypto/selectors'
import { checkAvailableCoin } from '../../store/crypto/cryptoSlice'


const CoinsSlider = () => {
  const navigate = useNavigate()
  const coins = useAppSelector(selectCoins)
  const dispatch = useAppDispatch()

  const settings: Settings = {
    dots: true,
    arrows: false,
    slidesToShow: coins.length >= 4 ? 2 : 1,
    slidesToScroll: 2,
    rows: 3,
    customPaging: i => (
       <Dots/>
    ),
  }

  const handleClickCoin = async (coin: string) => {
    const wait = await dispatch(checkAvailableCoin(coin, navigate))
    if (wait.available && wait.waiting_confirmation) {
      navigate(path.cryptoInfo())
      return
    }
    if (!wait.available) {
      return navigate(path.cryptoCoins())
    }
    navigate(path.qrCodeReady())
  }

  const renderCoins = () => {
    if (!coins?.length) return null
    return coins.map((e, ind) => {
      return (
         <Box key={`coinId-${e.coins}-${ind}`} sx={styles.coinBox} onClick={() => handleClickCoin(e.coins.token)}>
           <img style={{ width: '101px', height: '101px' }} src={e.coins.icon} alt="crypto"/>
           <Box className="cursor-pointer">
             <Typography
                mb={'10px'}
                lineHeight={'56px'}
                color={'white'}
                fontSize={'40px'}
                fontWeight={700}
                whiteSpace={'nowrap'}
             >
               {e.coins.name}
             </Typography>
             <Typography
                lineHeight={'40px'}
                color={'rgba(225, 225, 225, 0.5)'}
                fontSize={'44px'}
                whiteSpace={'nowrap'}
             >
               $ {e.price}
             </Typography>
           </Box>
         </Box>
      )
    })
  }

  return (
     <>
       <Box className="sliderCoin">
         <Slider {...settings}>
           {renderCoins()}
         </Slider>
       </Box>
     </>
  )
}

const styles = {
  coinBox: {
    background: '#1C1C1C',
    border: '3px solid #667080',
    borderRadius: '16px',
    maxWidth: '460px',
    padding: '32px',
    display: 'flex!important',
    gap: '16px',
    alignItems: 'center',
    marginBottom: '40px',
    overflow: 'hidden',
  },
}

export default CoinsSlider