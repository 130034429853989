import { Box, Typography } from '@mui/material'
import { makeStyles } from '../../hooks'
import errorImage from '../../images/error.svg'
import { ExitButton } from '../../components/Button'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { closeSession } from '../../store/kiosk/kioskSlice'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

export const Error = () => {

  const dispatch = useAppDispatch()
  const { error_code, error_message } = useAppSelector(state => state.error)
  const navigate = useNavigate()
  const contactEmail = localStorage.getItem('contact_email')
  const contactPhone = localStorage.getItem('contact_phone_number')

  const handleExit = () => {
    dispatch(closeSession(navigate))
  }

  return (
     <Box sx={styles.container}>
       <Box sx={styles.box}>
         <Box sx={styles.box2} textAlign={'center'}>
           <img width={'200px'} src={errorImage} alt="check"/>
           <Typography
              variant={'h1'}
              fontSize={50}
              fontWeight={700}
              margin={'85px 20px 60px 20px'}
              color={'white'}
           >
             {error_code === 'visible' ? error_message : t("error.server")}
           </Typography>
           {contactPhone && contactEmail && (
              <Typography
                 variant={'h1'}
                 fontSize={50}
                 fontWeight={700}
                 margin={'60px 20px 60px 20px'}
                 color={'white'}
                 lineHeight={'1.3'}
              >
                {t("error.contact")} {contactEmail} <br/> {t("crypto.patience.or")} <br/> {contactPhone}
              </Typography>)}
         </Box>
         <ExitButton
            colorText={'#1C1C1C'}
            fontSize={'28px'}
            background={'#07F285'}
            width={'159px'}
            onClick={handleExit}
         >
           {t("header.buttons.exit")}
         </ExitButton>
       </Box>
     </Box>
  )
}


const styles = makeStyles({
  container: {
    background: '#111111',
    height: '100vh',
  },
  box: {
    height: '100%',
    padding: '40px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box2: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
})