import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import PaymentFooter from '../../components/PaymentFooter/PaymentFooter'
import CoinsSlider from '../../components/Slider/CoinsSlider'
import { getAvailableCoins } from '../../store/crypto/cryptoSlice'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Shared/Loader/Loader'
import { selectCoinsLoading } from '../../store/crypto/selectors'
import { t } from 'i18next'

const Coins = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const loading = useAppSelector(selectCoinsLoading)

  useEffect(() => {
    dispatch(getAvailableCoins(navigate))
  }, [])

  if (loading) return <Loader />

  return (
     <Box sx={styles.container} mt={'88px'}>
       <Box>
         <Typography mb={'88px'} textAlign={'center'} color={'white'} fontSize={'64px'} fontWeight={700}>{t("crypto.coins")}</Typography>
         <CoinsSlider/>
       </Box>
       <PaymentFooter/>
     </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    height: '100%',
  },
}

export default Coins