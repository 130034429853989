import { Box, Theme } from '@mui/material'

export const Dots = ({ onClick }: {onClick?: any}) => {
  return (
    <Box onClick={onClick} sx={styles.dots}></Box>
  )
}

const styles = {
  dots: {
    width: '24px',
    height: '24px',
    background: (theme: Theme) => theme.palette.secondary.main,
    opacity: '50%',
    borderRadius: '50%',
  }
}