import { Box, Theme } from '@mui/material'
import { useEffect, useState } from 'react'
import { getProduct, setCurrentProduct } from '../../store/product/productSlice'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { AddCartProductButton, ArrowMiddleButton, BuyButton, UniversalButton } from '../../components/Button'
import addIcon from '../../images/addGreen.svg'
import { useNavigate } from 'react-router-dom'
import { path } from '../../enum/constant'
import { addItemCart } from '../../store/cart/cartSlice'
import { ProductSlider } from '../../components/Slider'
import arrowLeft from '../../images/arrow-mini-left.svg'
import arrowRight from '../../images/arrow-mini-right.svg'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from '../../helpers'
import { Drawer } from '../../components/Drawer'
import { useResponsive } from '../../hooks/useResponsive'

const ONE_SIZE = 'onesize'


export const Product = ({ product_id }: { product_id: number }) => {
  const dispatch = useAppDispatch()
  const product = useAppSelector(state => state.product.currentProduct)
  const navigate = useNavigate()
  const [count, setStateCount] = useState(1)
  const [countImage, setStateCountImage] = useState(0)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [size, setSize] = useState('')
  const { t } = useTranslation()
  const { isMinor } = useResponsive()

  useEffect(() => {
    (async () => {
      await dispatch(getProduct(product_id, navigate))
    })()
    return () => dispatch(setCurrentProduct({}))
  }, [product_id])

  useEffect(() => {
    setStateCountImage(product?.images?.length || 1)
  }, [product])
  if (!product) return null

  const getOneSize = () => {
    if (!product.variations) return
    const sizeKeys = Object.keys(product.variations[0])
    return product.variations[0][sizeKeys[0]].filter((el: any) => el.value !== ONE_SIZE)
  }

  const isOneSize = getOneSize()

  const formattedProduct = () => {
    if (!product.variations) return
    const sizeKeys = Object.keys(product.variations[0])
    const formatted = product.variations[0][sizeKeys[0]]
    let newProduct = {}
    let obj = null
    if (formatted) {
      obj = formatted.find((el: any) => el.value === (size !== '' ? size : ONE_SIZE))
    }

    newProduct = {
      ...product,
      id: obj?.product_item_id,
      count: count,
      size: obj?.value,
    }

    return { ...newProduct }
  }

  const handleCart = () => {
    dispatch(addItemCart(formattedProduct()))
    navigate(path.cart())
  }

  const handleAddCart = () => {
    dispatch(addItemCart(formattedProduct()))
  }

  const handleAddCount = () => {
    setStateCount(v => v + 1)
  }

  const handleRemCount = () => {
    if (count > 1)
      setStateCount(v => v - 1)
  }

  const getThingSize = (size: string) => {
    if (size) return setSize(size)
  }

  const renderSizeButton = () => {
    if (!isOneSize?.length) return
    return (
       <Box sx={{ flex: 1, marginLeft: '40px' }}>
         <UniversalButton
            children={!size ? 'Select a size' : size} //TODO: translate
            variant={'outlined'}
            colorText={'#FFFFFF'}
            fontSize={'28px'}
            background={'transparent'}
            fontWeight={700}
            width={'248px'}
            border={'3px solid #667080'}
            onClick={() => setOpenDrawer(true)}
         />
       </Box>
    )
  }

  const disabledProducts = () => {
    let disable = false
    if (!size && isOneSize?.length) disable = true
    return disable
  }

  return (
     <Box sx={{
       height: '100%',
       display: 'flex',
       flexDirection: 'column',
       justifyContent: 'space-between',
       marginTop: '64px',
     }}>
       <Box sx={{}}>
         <Box gap={isMinor ? '32px': ''} sx={{ display: 'flex', justifyContent: 'space-between' }}>
           <Box sx={styles.imageBox}>
             <Box sx={styles.imageBlock}>
               <ProductSlider/>
             </Box>
           </Box>
           <Box flex={isMinor ? 1 : ''} sx={styles.textBlock}>
             <Box sx={styles.name}>{product.name}</Box>
             <Box sx={styles.price}>$ {formatCurrency(product.price)}</Box>
             <Box sx={styles.description}>{product.description}</Box>
           </Box>
         </Box>
         <Box sx={styles.totalBoxMin}>
           <Box sx={styles.countBox}>
             <ArrowMiddleButton
                onClick={() => handleRemCount()}>
               <img src={arrowLeft} alt={'Arrow Left'}/>
             </ArrowMiddleButton>
             <Box sx={styles.count}>{count}</Box>
             <ArrowMiddleButton
                onClick={() => handleAddCount()}>
               <img src={arrowRight} alt={'Arrow Right'}/>
             </ArrowMiddleButton>
           </Box>
           {renderSizeButton()}
           <Box sx={styles.price2}>$ {formatCurrency(count * product.price)}</Box>
         </Box>
       </Box>
       <Box sx={styles.buttonBox}>
         <AddCartProductButton
            disabled={disabledProducts()}
            onClick={() => handleAddCart()}>
           {t('products.buttons.addToCart')}
           <img src={addIcon} alt={'Add Icon'}
           />
         </AddCartProductButton>
         <BuyButton
            disabled={disabledProducts()}
            onClick={() => handleCart()}
            children={t('products.buttons.buy')}
         />
       </Box>
       <Drawer
          variations={product.variations}
          size={size}
          getSize={getThingSize}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
       />
     </Box>
  )
}

const styles = ({
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  imageBox: {
    width: '460px',
    height: '360px',
    border: '3px solid #667080',
    background: '#1C1C1C',
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageBlock: {
    width: '420px',
  },
  textBlock: {
    width: '460px',
  },
  imgBox: {
    width: '100%',
    height: '360px',
    overflow: 'hidden',
    background: '#1C1C1C',
    border: (theme: Theme) => `3px solid ${theme.palette.secondary.main}`,
    borderRadius: '16px',
  },
  name: {
    color: '#ffffff',
    fontSize: '56px',
    fontWeight: '700',
  },
  price: {
    color: '#E1E1E1',
    fontSize: '40px',
    fontWeight: '400',
    marginTop: '16px',
  },
  description: {
    color: '#E1E1E1',
    fontSize: '28px',
    fontWeight: '400',
    marginTop: '32px',
    overflow: 'auto',
    height: '270px',
  },
  price2: {
    color: '#FFFFFF',
    fontSize: '40px',
    fontWeight: '500',
  },
  countBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '256px',
  },
  totalBoxMin: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '40px',
  },
  count: {
    color: '#E1E1E1',
    fontSize: '32px',
    fontWeight: '600',
  },
})