import { useEffect, useState } from "react";
import { mediaQueries } from '../enum/constant'

export const useResponsive = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMinor = width === mediaQueries.minor;
  const isMain = width === mediaQueries.main;

  return {
    isMinor,
    isMain,
  };
};